import { Map, TileLayer, Marker } from 'react-leaflet';
import classNames from 'classnames';
import Leaflet from 'leaflet';
import React, { Component } from 'react';

import './VolunteerCanvas.scss';
import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Icon from 'components/Icon';

const bem = blockElementModifier('VolunteerCanvas');

class VolunteerCanvas extends Component {
  componentDidMount() {
    this.handleOnFetchItems();
  }

  handleOnFetchItems(e) {
    const { onGetItems } = this.props;
    if (this.ref) {
      onGetItems(this.ref.leafletElement.getBounds());
    }
  }

  componentWillReceiveProps(nextProps) {
    const { resizeLayout, onResizeLayoutDone } = this.props;

    if (
      this.ref &&
      nextProps.resizeLayout &&
      resizeLayout !== nextProps.resizeLayout
    ) {
      this.ref.leafletElement.invalidateSize();
      onResizeLayoutDone();
    }
  }

  componentDidUpdate(prevProps) {
    const { onGetItems, latitude, longitude } = this.props;

    if (
      this.ref &&
      prevProps.latitude &&
      prevProps.longitude &&
      latitude !== prevProps.latitude &&
      longitude !== prevProps.longitude
    ) {
      onGetItems(this.ref.leafletElement.getBounds());
    }
  }

  render() {
    const {
      volunteers,
      latitude,
      longitude,
      onLeftSidebarToggle,
      leftSidebarIsOpen,
      history,
      match,
    } = this.props;
    return (
      <div className={bem()}>
        <button className={bem('toggle')} onClick={onLeftSidebarToggle}>
          <Icon
            glyph={leftSidebarIsOpen ? 'arrow-to-left' : 'arrow-to-right'}
            weight="light"
          />
        </button>
        <Map
          ref={ref => {
            this.ref = ref;
          }}
          onMoveEnd={() => this.handleOnFetchItems()}
          className={bem('map')}
          center={[latitude, longitude]}
          zoomControl={false}
          zoom={17}
          minZoom={17}
        >
          <TileLayer
            accessToken={process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            id="mapbox/streets-v11"
            url={process.env.REACT_APP_MAP_BOX_URL}
          />
          {volunteers.map(volunteer => (
            <Marker
              key={volunteer.id}
              position={volunteer.position}
              zIndexOffset={
                volunteer.id === parseInt(match.params.id) ? 500 : 0
              }
              onClick={() => {
                history.push(PATHS.VOLUNTEER_SHOW.replace(':id', volunteer.id));
              }}
              icon={Leaflet.divIcon({
                html: `<div class="${classNames(
                  'Indicator',
                  `Indicator--${
                    parseInt(match.params.id) === volunteer.id
                      ? 'large'
                      : 'small'
                  }`,
                  `Indicator--blue`,
                  `Indicator--pin`,
                )}" />`,
                iconSize:
                  parseInt(match.params.id) === volunteer.id
                    ? [23, 23]
                    : [13, 13],
                iconAnchor:
                  parseInt(match.params.id) === volunteer.id
                    ? [12, 23]
                    : [7, 13],
              })}
            />
          ))}
        </Map>
      </div>
    );
  }
}

export default VolunteerCanvas;
