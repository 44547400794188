import * as Nominatim from 'nominatim-browser';
import classNames from 'classnames';
import React, { Component } from 'react';
import ReactGeolookup from 'react-geolookup';
import Icon from 'components/Icon';

class Geolookup extends Component {
  render() {
    const { showButton } = this.props;
    return (
      <div className={classNames('Geolookup', { 'Geolookup--hasButton': showButton })}>
        <div className="Geolookup__control">
          <ReactGeolookup
            ref={el => (this._geoSuggest = el)}
            placeholder="Search for an address"
            onSuggestsLookup={e => this.onSuggestsLookup(e)}
            onSuggestSelect={e => this.onSuggestSelect(e)}
            getSuggestLabel={e => this.getSuggestLabel(e)}
            onGeocodeSuggest={e => this.onGeocodeSuggest(e)}
            autoActivateFirstSuggest={true}
          />
        </div>
        {showButton && (
          <button className="Geolookup__button">
            <Icon
              glyph="search"
              weight="regular"
            />
          </button>
        )}
      </div>
    );
  }

  onSuggestsLookup(userInput) {
    return Nominatim.geocode({
      street: userInput,
      city: 'Vancouver',
      state: 'BC',
      country: 'CA',
      addressdetails: true,
    });
  }

  onGeocodeSuggest(suggest) {
    return {
      ...suggest,
    };
  }

  onSuggestSelect(suggest) {
    const { onChange } = this.props;
    if (suggest.raw) {
      onChange({
        latlng: {
          lat: suggest.raw.lat,
          lng: suggest.raw.lon,
        },
      });
    }
  }

  getSuggestLabel(suggest) {
    return suggest.display_name;
  }
}

export default Geolookup;
