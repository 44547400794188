import { TYPES } from 'common/constants';

const getCurrentCityEnd = (state, { payload }) => {
  return {
    ...state,
    currentCity: payload,
  };
};

const getCurrentUserEnd = (state, { payload }) => {
  return {
    ...state,
    currentUser: payload,
  };
};

const getCurrentPositionEnd = (state, { payload }) => {
  if (payload) {
    return {
      ...state,
      currentPosition: payload,
    };
  }
  return state;
};

const logout = state => {
  return {
    ...state,
    token: null,
  };
};

const loginEnd = (state, { payload }) => {
  return {
    ...state,
    token: payload.token,
    message: null,
  };
};

const resetPasswordEnd = (state, { payload }) => {
  return {
    ...state,
    token: payload.token,
    message: null,
  };
};

const loginError = (state, { payload }) => {
  return {
    ...state,
    message: payload.message,
  };
};

const registerEnd = (state, { payload }) => {
  return {
    ...state,
    token: payload.token,
    message: null,
  };
};

const registerError = (state, { payload }) => {
  return {
    ...state,
    message: payload.message,
  };
};

const getNotificationsEnd = (state, { payload }) => {
  return {
    ...state,
    notifications: payload,
  };
};

const setPosition = (state, { payload }) => {
  return {
    ...state,
    currentPosition: payload,
  };
};

const deleteNotificationEnd = (state, { payload }) => {
  return {
    ...state,
    notifications: state.notifications.filter(
      notification => notification.id !== payload,
    ),
  };
};

export const clearMessage = state => {
  return {
    ...state,
    message: null,
  };
};

export default {
  [TYPES.GET_CURRENT_CITY_END]: getCurrentCityEnd,
  [TYPES.GET_CURRENT_POSITION_END]: getCurrentPositionEnd,
  [TYPES.GET_CURRENT_USER_END]: getCurrentUserEnd,
  [TYPES.GET_NOTIFICATIONS_END]: getNotificationsEnd,
  [TYPES.LOGIN_END]: loginEnd,
  [TYPES.LOGIN_ERROR]: loginError,
  [TYPES.LOGOUT]: logout,
  [TYPES.REGISTER_END]: registerEnd,
  [TYPES.REGISTER_ERROR]: registerError,
  [TYPES.SET_POSITION]: setPosition,
  [TYPES.RESET_PASSWORD_END]: resetPasswordEnd,
  [TYPES.DELETE_NOTIFICATION_END]: deleteNotificationEnd,
  [TYPES.CLEAR_MESSAGE]: clearMessage,
};
