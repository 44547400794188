import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';

import { PATHS } from 'common/constants';
import CityHOC from 'components/CityHOC';
import CompleteProfile from 'pages/Auth/CompleteProfile';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import Login from 'pages/Auth/Login';
import Register from 'pages/Auth/Register';
import ResetPassword from 'pages/Auth/ResetPassword';
import Assets from 'pages/Assets';
import store from 'store';
import Volunteer from 'pages/Auth/Volunteer';
import Volunteers from 'pages/Volunteers';

function App() {
  return (
    <Provider store={store}>
      <CityHOC>
        {currentCity => (
          <BrowserRouter>
            <Switch>
              <Route path={PATHS.LOGIN} component={Login} />
              <Route path={PATHS.REGISTER} component={Register} />
              <Route path={PATHS.VOLUNTEER} component={Volunteer} />
              <Route path={PATHS.COMPLETE_PROFILE} component={CompleteProfile} />
              <Route path={PATHS.FORGOT_PASSWORD} component={ForgotPassword} />
              <Route path={PATHS.RESET_PASSWORD} component={ResetPassword} />
              {currentCity.is_adopting_enabled && (
                <Route path={PATHS.ASSET_LIST} component={Assets} />
              )}
              {currentCity.is_volunteering_enabled && (
                <Route path={PATHS.VOLUNTEER_LIST} component={Volunteers} />
              )}
              <Route path={PATHS.ROOT}>
                <Redirect to={currentCity.is_adopting_enabled ? PATHS.ASSET_ROOT : currentCity.is_volunteering_enabled ? PATHS.VOLUNTEER_ROOT : '/500'} />
              </Route>
            </Switch>
          </BrowserRouter>
        )}
      </CityHOC>
    </Provider>
  );
}

export default App;
