import { Grid } from 'react-flexbox-grid';
import { Link, Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import validate from 'validate.js';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Button from 'components/Button';
import Field from 'components/Field';
import Hint from 'components/Hint';
import Input from 'components/Input';
import LayoutWithSplit from 'components/LayoutWithSplit';
import Toast from 'components/Toast';
import Steps from 'components/Steps';

const bem = blockElementModifier('Register');

class Register extends Component {
  state = {
    input: {
      email: '',
      password_confirmation: '',
      password: '',
    },
    warnings: {},
  };

  componentDidMount() {
    const { onClearMessage } = this.props;
    onClearMessage();
  }

  setInput(nextInput) {
    const { input, ...state } = this.state;
    this.setState({
      ...state,
      input: {
        ...input,
        ...nextInput,
      },
    });
  }

  handleOnSignUp() {
    const { input } = this.state;
    const { onRegister } = this.props;
    const schema = {
      email: { email: true },
      password: { length: { minimum: 6 } },
      password_confirmation: {
        equality: 'password',
      },
    };
    const warnings = validate(input, schema);
    if (warnings) {
      this.setState({ warnings });
    } else {
      this.setState({ warnings: {} });
      onRegister(input);
    }
  }

  render() {
    const { message, hasValidToken } = this.props;
    const { input, warnings } = this.state;

    if (hasValidToken) {
      return <Redirect to={PATHS.COMPLETE_PROFILE} />;
    }

    return (
      <LayoutWithSplit
        register
      >
        <div className={bem()}>
          <Grid fluid>
            <div className={bem('headline')}>Create your account</div>
            {message && <Toast message={message} />}
            <Steps step={1} />
          </Grid>
          <Grid fluid>
            <form
              onSubmit={e => {
                e.preventDefault();
                this.handleOnSignUp(e);
              }}
            >
              <Field>
                <Input
                  label="Email"
                  type="email"
                  value={input.email}
                  warning={warnings.email}
                  onChange={e =>
                    this.setState({
                      ...this.state,
                      input: { ...input, email: e.target.value },
                    })
                  }
                  autocapitalize="none"
                  required
                />
              </Field>
              <Field>
                <Input
                  label="Password"
                  type="text"
                  value={input.password}
                  warning={warnings.password}
                  onChange={e =>
                    this.setState({
                      ...this.state,
                      input: { ...input, password: e.target.value },
                    })
                  }
                  required
                  mask
                />
              </Field>
              <Field>
                <Input
                  label="Password Confirmation"
                  type="text"
                  value={input.password_confirmation}
                  warning={warnings.password_confirmation}
                  onChange={e =>
                    this.setState({
                      ...this.state,
                      input: {
                        ...input,
                        password_confirmation: e.target.value,
                      },
                    })
                  }
                  required
                  mask
                />
              </Field>
              <Button block label="Sign Up" />
              <Hint>
                By submitting my personal information, I accept the{' '}
                <Link to={PATHS.ROOT}>Privacy Policy</Link> and{' '}
                <Link to={PATHS.ROOT}>Terms of Service</Link>.
              </Hint>
            </form>
          </Grid>
        </div>
      </LayoutWithSplit>
    );
  }
}

export default Register;
