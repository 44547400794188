import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { popupOpen, popupClose } from 'store/actions/canvas';
import {
  selectAssetsWithFiltersApplied,
  selectFocus,
  selectShouldBlur,
} from 'store/selectors/canvas';
import AssetCanvas from './AssetCanvas';
import {
  selectResizeLayout,
  selectLeftSidebarIsOpen,
} from 'store/selectors/layout';
import { resizeLayoutDone, leftSidebarToggle } from 'store/actions/layout';

const mapStateToProps = state => ({
  assets: selectAssetsWithFiltersApplied(state),
  focus: selectFocus(state),
  resizeLayout: selectResizeLayout(state),
  leftSidebarIsOpen: selectLeftSidebarIsOpen(state),
  shouldBlur: selectShouldBlur(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onLeftSidebarToggle: leftSidebarToggle,
      onPopupClose: popupClose,
      onPopupOpen: popupOpen,
      onResizeLayoutDone: resizeLayoutDone,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssetCanvas);
