import { Link } from 'react-router-dom';
import React from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Avatar from 'components/Avatar';
import VolunteersFilter from 'components/VolunteerFilter';
import Welcome from 'components/Welcome';

const bem = blockElementModifier('VolunteersList');

function VolunteersList({ volunteers }) {
  return (
    <div className={bem()}>
      <div className={bem('volunteers')}>
        <Welcome
          headline="Offer support for those in need"
          strapline="This platform allows those who need support with non-medical essentials, to connect with volunteers in their community who are willing to help."
        />
        <VolunteersFilter />
        {volunteers.map(volunteer => (
          <div key={volunteer.id} className={bem('volunteer')}>
            <div className={bem('enemies')}>
              <div className={bem('top')}>
                <Avatar />
                <div className={bem('details')}>
                  <div className={bem('name')}>
                    {volunteer.first_name} {volunteer.last_name}
                  </div>
                  <div className={bem('distance')}>~ 1 km away from you</div>
                </div>
              </div>
              <div>
                <Link
                  to={PATHS.VOLUNTEER_SHOW.replace(':id', volunteer.id)}
                  className="Button Button--small"
                >
                  Details
                </Link>
              </div>
            </div>
            <div className={bem('services')}>
              {volunteer.services.map((service, i) => (
                <div key={i} className={bem('service')}>
                  {service}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VolunteersList;
