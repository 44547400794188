import React from 'react';

import { blockElementModifier } from 'common/helpers';
import Geolookup from 'components/Geolookup';
import Navigation from 'components/Navigation';

const bem = blockElementModifier('Header');

function Header({ currentCity, onSetPosition }) {
  return (
    <div className={bem()}>
      <div className={bem('brand')}>
        <div className={bem('logo')}>
          {currentCity.logo ? (
            <img
              src={currentCity.logo}
              alt={currentCity.name}
            />
          ) : currentCity.name}
        </div>
        <div className={bem('geolookup')}>
          <Geolookup
            showButton={true}
            onChange={e =>
              onSetPosition({
                latitude: e.latlng.lat,
                longitude: e.latlng.lng,
              })
            }
          />
        </div>
      </div>
      <Navigation />
    </div>
  );
}

export default Header;
