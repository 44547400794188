import { Grid } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';

const bem = blockElementModifier('LayoutWithSplit');

function LayoutWithSplit({ children, currentCity, login, register }) {
  return (
    <div className={classNames(bem(), currentCity.theme)}>
      <div className={bem('left')}>
        <div className={bem('header')}>
          <div className={bem('container')}>
            <Grid fluid>
              <Link to={PATHS.ROOT} className={bem('logo')}>
                {currentCity.logo ? (
                  <img
                    src={currentCity.logo}
                    alt={currentCity.name}
                  />
                ) : currentCity.name}
              </Link>
            </Grid>
          </div>
        </div>
        <div className={bem('body')}>
          <div className={bem('container')}>{children}</div>
        </div>
      </div>
      <div className={bem('right')}>
        <div className={bem('header')}>
          <div className={bem('container')}>
            <Grid fluid>
              {register && (
                <>
                  Have an account already?{' '}
                  <Link className={bem('link')} to={PATHS.LOGIN}>
                    Log In
                  </Link>
                </>
              )}
              {login && (
                <>
                  Don’t have an account yet?{' '}
                  <Link className={bem('link')} to={PATHS.REGISTER}>
                    Sign Up
                  </Link>
                </>
              )}
            </Grid>
          </div>
        </div>
        <div className={bem('body')}>
          <div className={bem('container')}>
            <Grid fluid>
              <div className={bem('enticer')}>
                <div className={bem('enticerHeadline')}>
                  Help keep your city beautiful
                </div>
                <div className={bem('enticerStrapline')}>
                  Everyone can get involved! Individuals, groups, businesses,
                  and schools can all adopt a catch basin. You can help keep
                  your city clean and functional by clearing leaves, debris, and
                  litter from your catch basin after heavy rainfalls.
                </div>
              </div>
              <div className={bem('footer')}>
                <div className={bem('footerHeadline')}>
                  Do you work for a city?
                </div>
                <div className={bem('footerStrapline')}>
                  The Adopt-A-City platform can help support your city and its
                  residents.{' '}
                  <Link className={bem('link')} to={PATHS.ROOT}>
                    Schedule your free demo
                  </Link>
                  .
                </div>
              </div>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutWithSplit;
