import classNames from 'classnames';
import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Progress');

class Progress extends Component {
  state = { ready: false };

  componentDidMount() {
    this.timeoutId = setTimeout(
      function() {
        this.setState({ ready: true });
      }.bind(this),
      100,
    );
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { ready } = this.state;
    return (
      <div
        className={classNames(bem(), {
          [bem(null, 'ready')]: ready,
        })}
      >
        <div className={bem('indicator')} />
      </div>
    );
  }
}

export default Progress;
