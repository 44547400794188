import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import classNames from 'classnames';
import React, { useState } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Icon from 'components/Icon';

const bem = blockElementModifier('Welcome');

function Welcome({ currentCity, hasValidToken, headline, strapline }) {
  const [isOpen, setIsOpen] = useState(!hasValidToken);
  return (
    <div className={classNames(bem(), { [bem(null, 'open')]: isOpen })}>
      <button className={bem('toggle')} onClick={e => setIsOpen(!isOpen)}>
        Adopt-A-City Overview
        <Icon glyph={`angle-${isOpen ? 'up' : 'down'}`} weight="solid" />
      </button>
      <div className={bem('accordion')}>
        <div className={bem('body')}>
          <div className={bem('headline')}>{headline}</div>
          <div className={bem('strapline')}>{strapline}</div>
          {!hasValidToken && (
            <Row>
              <Col xs={6}>
                <Link to={PATHS.LOGIN} className="Button Button--block">
                  Log In
                </Link>
              </Col>
              <Col xs={6}>
                <Link to={PATHS.REGISTER} className="Button Button--block">
                  Sign Up
                </Link>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
}

export default Welcome;
