import _ from 'lodash';

export const selectLeftSidebarIsOpen = state =>
  _.get(state, ['leftSidebarIsOpen'], true);

export const selectRightSidebarIsOpen = state =>
  _.get(state, ['rightSidebarIsOpen'], false);

export const selectIsMenuOpen = state => _.get(state, ['isMenuOpen'], false);

export const selectResizeLayout = state =>
  _.get(state, ['resizeLayout'], false);
