import { createReducer } from '@reduxjs/toolkit';

import canvas from './canvas';
import layout from './layout';
import popup from './popup';
import root from './root';

const initialState = {
  assets: [],
};

export default createReducer(initialState, {
  ...canvas,
  ...layout,
  ...popup,
  ...root,
});
