import './VolunteerFilter.scss';
import VolunteerFilter from './VolunteerFilter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setServiceFilters, resetServiceFilters } from 'store/actions/canvas';
import { selectServiceFilters } from 'store/selectors/canvas';

const mapStateToProps = state => ({
  serviceFilters: selectServiceFilters(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSetServiceFilters: setServiceFilters,
      onResetServiceFilters: resetServiceFilters,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerFilter);
