import React, { useState } from 'react';
import classNames from 'classnames';

import { blockElementModifier } from 'common/helpers';
import Input from 'components/Input';

const services = [
  'Grocery shopping and delivery',
  'Prescription pickup and delivery',
  'Meal preparation and delivery',
  'Phone and/or virtual visits',
  'Dog walking',
  'Laundry services',
];

const bem = blockElementModifier('Filters');

function VolunteerFilter({
  serviceFilters,
  onSetServiceFilters,
  onResetServiceFilters,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={classNames(bem(), { [bem(null, 'open')]: isOpen })}>
      <div className={bem('header')}>
        <button className="Filters__toggle" onClick={() => setIsOpen(!isOpen)}>
          <div className="Filters__toggleIcon">
            <i className="Icon fas fa-sliders-h"></i>
          </div>
          <div className="Filters__toggleLabel">Filters</div>
          <span className="Filters__toggleCount">{serviceFilters.length}</span>
        </button>
        <button
          onClick={onResetServiceFilters}
          className="Button Button--semantic"
        >
          Reset Filters
        </button>
      </div>
      <div className="Filters__accordion">
        <div className="Filters__body">
          <div className="Filters__heading">Service</div>
          {services.map((service, i) => (
            <div key={i} className="Filters__item Filters__item--Available">
              <Input
                value={service}
                checked={serviceFilters.indexOf(service) > -1}
                onChange={e =>
                  onSetServiceFilters(
                    serviceFilters &&
                      serviceFilters.indexOf(e.target.value) > -1
                      ? serviceFilters.filter(x => x !== e.target.value)
                      : [...serviceFilters, e.target.value],
                  )
                }
                name="status"
                type="checkbox"
                label={service}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VolunteerFilter;
