import { Col, Row } from 'react-flexbox-grid';
import classNames from 'classnames';
import React, { useState } from 'react';

import { blockElementModifier } from 'common/helpers';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';

const bem = blockElementModifier('Filters');

const sorts = ['Distance', 'Last Serviced', 'Status A-Z'];

function Filters({
  astatus,
  atype,
  onResetFilters,
  onSetSortBy,
  onSetStatus,
  onSetType,
  sortBy,
  statuses,
  types,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={classNames(bem(), { [bem(null, 'open')]: isOpen })}>
      <div className={bem('header')}>
        <button className={bem('toggle')} onClick={e => setIsOpen(!isOpen)}>
          <div className={bem('toggleIcon')}>
            <Icon weight="solid" glyph="sliders-h" />
          </div>
          <div className={bem('toggleLabel')}>Filters</div>
          <span className={bem('toggleCount')}>{astatus.length}</span>
        </button>
        <Button
          label="Reset Filters"
          onClick={e => onResetFilters()}
          semantic
        />
      </div>
      <div className={bem('accordion')}>
        <div className={bem('body')}>
          <Row>
            <Col xs={4}>
              <div className={bem('heading')}>Status</div>
              {statuses.map((status, index) => (
                <div
                  className={classNames(bem('item'), bem('item', status))}
                  key={index}
                >
                  <Input
                    value={status}
                    checked={astatus.indexOf(status) > -1}
                    onChange={e =>
                      onSetStatus(
                        astatus && astatus.indexOf(e.target.value) > -1
                          ? astatus.filter(x => x !== e.target.value)
                          : [...astatus, e.target.value],
                      )
                    }
                    name="status"
                    type="checkbox"
                    label={status}
                  />
                </div>
              ))}
            </Col>
            <Col xs={4}>
              <div className={bem('heading')}>Type</div>
              <div className={bem('item')}>
                <Input
                  value={''}
                  onChange={e => onSetType('')}
                  checked={atype === ''}
                  name="type"
                  type="radio"
                  label="All"
                />
              </div>
              {types.map(type => (
                <div className={bem('item')} key={type}>
                  <Input
                    value={type}
                    onChange={e => onSetType(e.target.value)}
                    name="type"
                    type="radio"
                    label={type}
                  />
                </div>
              ))}
            </Col>
            <Col xs={4}>
              <div className={bem('heading')}>Sort By</div>
              {sorts.map(sort => (
                <div className={bem('item')} key={sort}>
                  <Input
                    value={sort}
                    onChange={e => onSetSortBy(e.target.value)}
                    checked={sortBy === sort}
                    name="status"
                    type="radio"
                    label={sort}
                  />
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Filters;
