import classNames from 'classnames';
import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Steps');

function Steps({ step }) {
  return (
    <div className={bem()}>
      <div className={bem('step')}>
        <div
          className={classNames(bem('count'), {
            [bem('count', 'active')]: step === 1,
          })}
        >
          1
        </div>
        Account Setup
      </div>
      <div className={bem('step')}>
        <div
          className={classNames(bem('count'), {
            [bem('count', 'active')]: step === 2,
          })}
        >
          2
        </div>
        Personal Info
      </div>
    </div>
  );
}

export default Steps;
