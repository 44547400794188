import { TYPES } from 'common/constants';

const getAssetsEnd = (state, { payload }) => {
  return {
    ...state,
    assets: payload.map(next => {
      const previous = state.assets
        ? state.assets.find(asset => asset.id === next.id)
        : null;
      return previous ? previous : next;
    }),
  };
};

const getVolunteersEnd = (state, { payload }) => {
  return {
    ...state,
    volunteers: payload.map(next => {
      const previous = state.volunteers
        ? state.volunteers.find(volunteer => volunteer.id === next.id)
        : null;
      return previous ? previous : next;
    }),
  };
};

const setType = (state, { payload }) => {
  return {
    ...state,
    type: payload.type,
  };
};

const setStatus = (state, { payload }) => {
  return {
    ...state,
    status: payload.status,
  };
};

export const setServiceFilters = (state, { payload }) => {
  return {
    ...state,
    serviceFilters: payload,
  };
};

export const resetServiceFilters = ({ serviceFilters, ...state }) => {
  return state;
};

const focusAsset = (state, { payload }) => {
  return {
    ...state,
    focus: payload,
  };
};

const blurAsset = (state, { payload }) => {
  return {
    ...state,
    focus: null,
  };
};

const popupOpen = (state, { payload }) => {
  return {
    ...state,
    popup: {
      asset_id: payload,
      view: 'details',
    },
  };
};

const popupClose = (state, { payload }) => {
  return {
    ...state,
    popup: {},
  };
};

const setSortBy = (state, { payload }) => {
  return {
    ...state,
    sortBy: payload,
  };
};

const viewAsset = (state, { payload }) => {
  return {
    ...state,
    viewAsset: payload.id,
    focus: null,
    // currentPosition: payload.position,
  };
};

const getAssetEventsEnd = (state, { payload }) => {
  return {
    ...state,
    assets: state.assets.map(asset => {
      if (asset.id !== payload.id) {
        return asset;
      }
      return {
        ...asset,
        events: payload.events,
      };
    }),
  };
};

const resetFilters = ({ sortBy, status, type, ...state }) => state;

export default {
  [TYPES.BLUR_ASSET]: blurAsset,
  [TYPES.FOCUS_ASSET]: focusAsset,
  [TYPES.GET_ASSET_EVENTS_END]: getAssetEventsEnd,
  [TYPES.GET_ASSETS_END]: getAssetsEnd,
  [TYPES.GET_VOLUNTEERS_END]: getVolunteersEnd,
  [TYPES.POPUP_CLOSE]: popupClose,
  [TYPES.POPUP_OPEN]: popupOpen,
  [TYPES.RESET_FILTERS]: resetFilters,
  [TYPES.SET_SORT_BY]: setSortBy,
  [TYPES.SET_STATUS]: setStatus,
  [TYPES.SET_TYPE]: setType,
  [TYPES.VIEW_ASSET]: viewAsset,
  [TYPES.SET_SERVICE_FILTERS]: setServiceFilters,
  [TYPES.RESET_SERVICE_FILTERS]: resetServiceFilters,
};
