import React from 'react';

import { ACTION_ICON_MAP } from 'common/constants';
import { blockElementModifier } from 'common/helpers';
import Icon from 'components/Icon';

const bem = blockElementModifier('Action');

function Action({ action }) {
  return (
    <div className={bem()}>
      <div className={bem('icon')}>
        <Icon weight="solid" glyph={ACTION_ICON_MAP[action]} />
      </div>
      <div className={bem('label')}>{action}</div>
    </div>
  );
}

export default Action;
