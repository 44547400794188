import { Link } from 'react-router-dom';
import classNames from 'classnames';
import React, { useState } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Alert from 'components/Alert';
import Icon from 'components/Icon';
import Profile from 'components/Profile';

const bem = blockElementModifier('Navigation');

function Navigation({
  currentUser,
  hasValidToken,
  onLogout,
  isMenuOpen,
  onSetIsMenuOpen,
}) {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  return (
    <div className={bem()}>
      <button
        className={bem('hamburger')}
        onClick={() => onSetIsMenuOpen(!isMenuOpen)}
      >
        <Icon
          glyph="bars"
          weight="regular"
        />
      </button>
      <div
        className={classNames(bem('menu'), {
          [bem('menu', 'open')]: isMenuOpen,
        })}
      >
        <div className={bem('items')}>
          <div className={bem('wrapper')}>
            {hasValidToken && currentUser ? (
              <>
                <Alert />
                <button
                  type="button"
                  className={bem('item')}
                  onClick={() => setIsProfileOpen(true)}
                >
                  Profile
                </button>
                <button
                  className={bem('item')}
                  type="button"
                  onClick={e => {
                    onLogout();
                  }}
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link className="Button Button--semantic" to={PATHS.LOGIN}>
                  Log In
                </Link>
                <Link className="Button" to={PATHS.REGISTER}>
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      {isProfileOpen && <Profile onClose={() => setIsProfileOpen(false)} />}
    </div>
  );
}

export default Navigation;
