import React from 'react';

import { blockElementModifier } from 'common/helpers';
import Icon from 'components/Icon';
import Label from 'components/Label';

const bem = blockElementModifier('Select');

function Select({ label, options, value, ...props }) {
  return (
    <div className={bem()}>
      {label && <Label>{label}</Label>}
      <div className={bem('handle')}>
        <Icon weight="solid" glyph="caret-down" />
      </div>
      <select className={bem('control')} {...props}>
        <option></option>
        {options.map(option => (
          <option key={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}

export default Select;
