import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './ResetPassword.scss';
import { resetPassword, clearMessage } from 'store/actions/root';
import { selectHasValidToken } from 'store/selectors/root';
import ResetPassword from './ResetPassword';

const mapStateToProps = state => ({
  hasValidToken: selectHasValidToken(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onClearMessage: clearMessage,
      onResetPassword: resetPassword,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
