import classNames from 'classnames';
import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Indicator');

function Indicator({ color, size }) {
  return (
    <div
      className={classNames(bem(), {
        [bem(null, color)]: color,
        [bem(null, size)]: size,
      })}
    />
  );
}

export default Indicator;
