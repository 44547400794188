import { TYPES } from 'common/constants';

const leftSidebarToggle = state => {
  return {
    ...state,
    isMenuOpen: false,
    leftSidebarIsOpen: !state.leftSidebarIsOpen,
    rightSidebarIsOpen: false,
  };
};

const rightSidebarToggle = state => {
  return {
    ...state,
    isMenuOpen: false,
    leftSidebarIsOpen: false,
    rightSidebarIsOpen: !state.rightSidebarIsOpen,
  };
};

const resizeLayout = state => {
  return {
    ...state,
    resizeLayout: true,
  };
};

const resizeLayoutDone = state => {
  return {
    ...state,
    resizeLayout: false,
  };
};

const setIsMenuOpen = (state, { payload }) => {
  return {
    ...state,
    isMenuOpen: payload,
    leftSidebarIsOpen: false,
    rightSidebarIsOpen: false,
  };
};

const markNotificationsAsReadEnd = state => {
  return {
    ...state,
    notifications: state.notifications.map(notification => ({
      ...notification,
      is_read: true,
    })),
  };
};

export default {
  [TYPES.TOGGLE_LEFT_SIDEBAR]: leftSidebarToggle,
  [TYPES.TOGGLE_RIGHT_SIDEBAR]: rightSidebarToggle,
  [TYPES.RESIZE_LAYOUT]: resizeLayout,
  [TYPES.RESIZE_LAYOUT_DONE]: resizeLayoutDone,
  [TYPES.MARK_NOTIFICATION_AS_READ_END]: markNotificationsAsReadEnd,
  [TYPES.SET_IS_MENU_OPEN]: setIsMenuOpen,
};
