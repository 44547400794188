import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Loader');

function Loader() {
  return <div className={bem()}>Loading</div>;
}

export default Loader;
