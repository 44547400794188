import { connect } from 'react-redux';

import './CityHOC';
import CityHOC from './CityHOC';
import { bindActionCreators } from 'redux';
import { getCurrentCity } from 'store/actions/root';
import { selectCurrentCity } from 'store/selectors/root';

const mapStateToProps = state => ({
  currentCity: selectCurrentCity(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onGetCurrentCity: getCurrentCity,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CityHOC);
