import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { leftSidebarToggle } from 'store/actions/layout';
import { selectLeftSidebarIsOpen } from 'store/selectors/layout';
import { selectVolunteersWithFiltersApplied } from 'store/selectors/canvas';
import VolunteerCanvas from './VolunteerCanvas';

const mapStateToProps = state => ({
  leftSidebarIsOpen: selectLeftSidebarIsOpen(state),
  volunteers: selectVolunteersWithFiltersApplied(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onLeftSidebarToggle: leftSidebarToggle,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VolunteerCanvas);
