import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './Alert.scss';
import Alert from './Alert';
import { selectUnreadNotifications } from 'store/selectors/root';
import { rightSidebarToggle } from 'store/actions/layout';

const mapStateToProps = state => ({
  unreadNotifications: selectUnreadNotifications(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onRightSidebarToggle: rightSidebarToggle,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
