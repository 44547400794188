import { TYPES } from 'common/constants';
import moment from 'moment';

const adoptBegin = state => {
  return {
    ...state,
    popup: {
      ...state.popup,
      isLoading: true,
    },
  };
};

const adoptEnd = (state, { payload }) => {
  return {
    ...state,
    popup: {
      asset_id: state.popup.asset_id,
      view: 'adopted',
    },
    assets: state.assets.map(asset => {
      if (asset.id !== payload) {
        return asset;
      }
      return {
        ...asset,
        status: 'Yours',
        owner: {
          ...state.currentUser,
        },
      };
    }),
  };
};

const abandonSubmitEnd = (state, { payload }) => {
  return {
    ...state,
    popup: {
      ...state.popup,
      view: 'details',
    },
    assets: state.assets.map(asset => {
      if (asset.id !== payload) {
        return asset;
      }
      return {
        ...asset,
        status: 'Available',
        owner: null,
      };
    }),
  };
};

const markAsServiced = (state, { payload }) => {
  return {
    ...state,
    popup: {
      ...state.popup,
      view: 'serviced',
    },
    assets: state.assets.map(asset => {
      if (asset.id !== payload) {
        return asset;
      }
      return {
        ...asset,
        last_serviced_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
    }),
  };
};

const abandon = state => {
  return {
    ...state,
    popup: {
      ...state.popup,
      view: 'abandon',
    },
  };
};

const notifyOwnerDone = (state, { payload }) => {
  return {
    ...state,
    popup: {
      ...state.popup,
      isLoading: false,
      view: 'notify',
    },
    assets: state.assets.map(asset => {
      if (asset.id !== payload) {
        return asset;
      }
      return {
        ...asset,
        last_notified_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
    }),
  };
};

const submitNotificationEnd = state => {
  return {
    ...state,
    popup: {
      ...state.popup,
      view: 'notified',
    },
  };
};

const loginRequired = state => {
  return {
    ...state,
    popup: {
      ...state.popup,
      view: 'login',
    },
  };
};

const submitNotificationBegin = state => {
  return {
    ...state,
    popup: {
      ...state.popup,
      isLoading: true,
    },
  };
};

const reset = state => {
  return {
    ...state,
    popup: {
      ...state.popup,
      view: 'details',
    },
  };
};

export default {
  [TYPES.ABANDON_SUBMIT_END]: abandonSubmitEnd,
  [TYPES.ABANDON]: abandon,
  [TYPES.ADOPT_BEGIN]: adoptBegin,
  [TYPES.ADOPT_END]: adoptEnd,
  [TYPES.LOGIN_REQUIRED]: loginRequired,
  [TYPES.MARK_AS_SERVICED_END]: markAsServiced,
  [TYPES.NOTIFY_OWNER_END]: notifyOwnerDone,
  [TYPES.RESET]: reset,
  [TYPES.SUBMIT_NOTIFICATION_BEGIN]: submitNotificationBegin,
  [TYPES.SUBMIT_NOTIFICATION_END]: submitNotificationEnd,
};
