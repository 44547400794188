import { connect } from 'react-redux';

import './VolunteersList.scss';
import VolunteersList from './VolunteersList';
import { selectVolunteersWithFiltersApplied } from 'store/selectors/canvas';

const mapStateToProps = state => ({
  volunteers: selectVolunteersWithFiltersApplied(state),
});

export default connect(mapStateToProps)(VolunteersList);
