import React from 'react';

import { blockElementModifier } from 'common/helpers';
import Icon from 'components/Icon';

const bem = blockElementModifier('Warning');

function Warning({ title, items }) {
  return (
    <div className={bem()}>
      <div className={bem('title')}>{title}</div>
      <div className={bem('items')}>
        {items.map((item, i) => (
          <div key={i} className={bem('item')}>
            <div className={bem('icon')}>
              <Icon glyph="check" weight="solid" />
            </div>
            <div>
              <div className={bem('headline')}>{item.headline}</div>
              <div className={bem('strapline')}>{item.strapline}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Warning;
