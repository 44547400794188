import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './AssetNotify.scss';
import AssetNotify from './AssetNotify';
import { selectAssetById } from 'store/selectors/canvas';
import { selectHasValidToken } from 'store/selectors/root';
import { submitNotification } from 'store/actions/popup';

const mapStateToProps = (state, ownProps) => ({
  asset: selectAssetById(state, ownProps.match.params.id),
  hasValidToken: selectHasValidToken(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSubmitNotification: submitNotification,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssetNotify);
