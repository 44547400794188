import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './Volunteers';
import Volunteers from './Volunteers';
import { getVolunteers } from 'store/actions/canvas';
import {
  getCurrentPosition,
  getCurrentUser,
  getNotifications,
} from 'store/actions/root';
import {
  selectCurrentCity,
  selectCurrentPosition,
  selectHasValidToken,
} from 'store/selectors/root';

const mapStateToProps = state => ({
  currentCity: selectCurrentCity(state),
  currentPosition: selectCurrentPosition(state),
  hasValidToken: selectHasValidToken(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onGetVolunteers: getVolunteers,
      onGetCurrentPosition: getCurrentPosition,
      onGetCurrentUser: getCurrentUser,
      onGetNotifications: getNotifications,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Volunteers);
