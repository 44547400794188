import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';

import Loader from 'components/Loader';

class CityHOC extends Component {
  componentDidMount() {
    const { onGetCurrentCity } = this.props;
    onGetCurrentCity();
  }

  render() {
    const { children, currentCity } = this.props;
    if (!currentCity) {
      return <Loader />;
    }
    return (
      <DocumentTitle title={`${currentCity.name} | Adopt A City`}>
        {children(currentCity)}
      </DocumentTitle>
    );
  }
}

export default CityHOC;
