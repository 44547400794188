/**
 * Creates a BEM class name helper function
 *
 * @param {string} b
 * @returns {function}
 */
export const blockElementModifier = b => (e = null, m = null) =>
  `${b}${e ? `__${e}` : ''}${m ? `--${m}` : ''}`;

export const paginate = (array, size, offset) =>
  array.slice((offset - 1) * size, offset * size);
