import _ from 'lodash';
import { createSelector } from 'reselect';

/**
 * Select assets
 *
 * @param {object} state
 * @returns {array}
 */
export const selectAssets = state => _.get(state, ['assets'], []);

export const selectVolunteers = state => _.get(state, ['volunteers'], []);

export const selectVolunteerById = (state, id) =>
  selectVolunteers(state).find(volunteer => volunteer.id === parseInt(id));

/**
 * Select status
 *
 * @param {object} state
 * @returns {array}
 */
export const selectStatus = state => _.get(state, ['status'], null);

/**
 * Select sort by
 *
 * @param {object} state
 * @returns {string}
 */
export const selectSortBy = state => _.get(state, ['sortBy'], 'Distance');

/**
 * Select focus
 *
 * @param {object} state
 * @returns {int}
 */
export const selectFocus = state => _.get(state, ['focus'], null);

/**
 * Select asset statuses
 *
 * @param {object} state
 * @returns {array}
 */
export const selectAssetStatuses = state =>
  _.uniq(selectAssets(state).map(asset => asset.status));

/**
 * Select status with initial value
 *
 * @param {object} state
 * @returns {array}
 */
export const selectStatusWithInitialValue = createSelector(
  [selectStatus, selectAssetStatuses],
  (status, assetStatuses) => status || assetStatuses,
);

/**
 * Select type
 *
 * @param {object} state
 * @returns {array}
 */
export const selectType = state => _.get(state, ['type'], '');

/**
 * Select asset types
 *
 * @param {object} state
 */
export const selectAssetTypes = state =>
  _.uniq(selectAssets(state).map(asset => asset.type));

/**
 * Select assets by type
 *
 * @returns {array}
 */
export const selectAssetsByType = createSelector(
  [selectAssets, selectType],
  (assets, type) =>
    assets.filter(asset => (type === '' ? true : asset.type === type)),
);

/**
 * Select assets by status
 *
 * @returns {array}
 */
export const selectAssetsByStatus = createSelector(
  [selectAssets, selectStatusWithInitialValue],
  (assets, status) => assets.filter(asset => status.indexOf(asset.status) > -1),
);

/**
 * Select assets with filter applied
 *
 * @returns {array}
 */
export const selectAssetsWithFiltersApplied = createSelector(
  [selectAssets, selectType, selectStatusWithInitialValue],
  (assets, type, status) =>
    assets
      .filter(asset => (type === '' ? true : asset.type === type))
      .filter(asset => status.indexOf(asset.status) > -1),
);

export const selectAssetsWithFiltersAndSortApplied = createSelector(
  [selectAssetsWithFiltersApplied, selectSortBy],
  (assets, sortBy) => {
    switch (sortBy) {
      case 'Distance':
        return assets;
      case 'Status A-Z':
        return _.sortBy(assets, 'status');
      case 'Last Serviced':
        return _.sortBy(assets, 'last_serviced_at');
      default:
        return assets;
    }
  },
);

export const selectServiceFilters = state =>
  _.get(state, ['serviceFilters'], []);

export const selectVolunteersWithFiltersApplied = createSelector(
  [selectVolunteers, selectServiceFilters],
  (volunteers, serviceFilters) =>
    serviceFilters.length > 0
      ? volunteers.filter(volunteer =>
          volunteer.services.some(
            service => serviceFilters.indexOf(service) > -1,
          ),
        )
      : volunteers,
);

export const selectAssetById = (state, id) =>
  selectAssets(state).find(asset => asset.id === parseInt(id));

export const selectViewAsset = state => _.get(state, ['viewAsset'], null);

export const selectShouldBlur = createSelector(
  [selectViewAsset, selectFocus],
  (viewAsset, focus) => viewAsset || focus,
);

export const selectViewableAsset = createSelector(
  [selectAssetsWithFiltersApplied, selectViewAsset],
  (assets, viewAsset) => assets.find(asset => asset.id === viewAsset),
);
