import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './Volunteer.scss';
import { clearMessage, updateCurrentUser } from 'store/actions/root';
import Volunteer from './Volunteer';
import { selectHasValidToken, selectMessage } from 'store/selectors/root';

const mapStateToProps = state => ({
  hasValidToken: selectHasValidToken(state),
  message: selectMessage(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onUpdateCurrentUser: updateCurrentUser,
      onClearMessage: clearMessage,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Volunteer);
