import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './Navigation.scss';
import { logout } from 'store/actions/root';
import { setIsMenuOpen } from 'store/actions/layout';
import { selectCurrentUser, selectHasValidToken } from 'store/selectors/root';
import Navigation from './Navigation';
import { selectIsMenuOpen } from 'store/selectors/layout';

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  hasValidToken: selectHasValidToken(state),
  isMenuOpen: selectIsMenuOpen(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSetIsMenuOpen: setIsMenuOpen,
      onLogout: logout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
