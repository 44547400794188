import { TYPES } from 'common/constants';
import * as client from 'services/client';

const getCurrentPositionBegin = () => ({
  type: TYPES.GET_CURRENT_POSITION_BEGIN,
});

const getCurrentPositionEnd = payload => ({
  type: TYPES.GET_CURRENT_POSITION_END,
  payload,
});

export const getCurrentPosition = () => dispatch => {
  if (navigator.geolocation) {
    return Promise.all([
      dispatch(getCurrentPositionBegin()),
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          position =>
            dispatch(
              getCurrentPositionEnd({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              }),
            ),
          error => dispatch(getCurrentPositionEnd(null)),
        );
      }),
    ]);
  }
};

export const setPosition = payload => ({
  type: TYPES.SET_POSITION,
  payload,
});

const getCurrentCityBegin = payload => ({
  type: TYPES.GET_CURRENT_CITY_BEGIN,
  payload,
});

const getCurrentCityEnd = payload => ({
  type: TYPES.GET_CURRENT_CITY_END,
  payload,
});

export const getCurrentCity = () => dispatch =>
  Promise.all([
    dispatch(getCurrentCityBegin()),
    client.fetchCurrentCity().then(
      response => dispatch(getCurrentCityEnd(response)),
      error => console.log(error),
    ),
  ]);

const getCurrentUserBegin = payload => ({
  type: TYPES.GET_CURRENT_USER_BEGIN,
  payload,
});

const getCurrentUserEnd = payload => ({
  type: TYPES.GET_CURRENT_USER_END,
  payload,
});

export const getCurrentUser = () => dispatch =>
  Promise.all([
    dispatch(getCurrentUserBegin()),
    client.fetchCurrentUser().then(
      response => dispatch(getCurrentUserEnd(response)),
      error => console.log(error),
    ),
  ]);

const loginBegin = () => ({
  type: TYPES.LOGIN_BEGIN,
});

const loginEnd = payload => ({
  type: TYPES.LOGIN_END,
  payload,
});

const loginError = payload => ({
  type: TYPES.LOGIN_ERROR,
  payload,
});

export const login = input => dispatch =>
  Promise.all([
    dispatch(loginBegin()),
    client.login(input).then(
      response => dispatch(loginEnd(response)),
      error => dispatch(loginError(error.response.data)),
    ),
  ]);

const registerBegin = () => ({
  type: TYPES.REGISTER_BEGIN,
});

const registerEnd = payload => ({
  type: TYPES.REGISTER_END,
  payload,
});

const registerError = payload => ({
  type: TYPES.REGISTER_ERROR,
  payload,
});

export const register = input => dispatch =>
  Promise.all([
    dispatch(registerBegin()),
    client.register(input).then(
      response => dispatch(registerEnd(response)),
      error => dispatch(registerError(error.response.data)),
    ),
  ]);

export const logout = () => ({
  type: TYPES.LOGOUT,
});

export const getNotificationsBegin = () => ({
  type: TYPES.GET_NOTIFICATIONS_BEGIN,
});

export const getNotificationsEnd = payload => ({
  type: TYPES.GET_NOTIFICATIONS_END,
  payload,
});

export const getNotifications = () => dispatch =>
  Promise.all([
    dispatch(getNotificationsBegin()),
    client.fetchNotifications().then(
      response => dispatch(getNotificationsEnd(response)),
      error => console.log(error),
    ),
  ]);

const forgotPasswordBegin = () => ({
  type: TYPES.FORGOT_PASSWORD_BEGIN,
});

const forgotPasswordEnd = () => ({
  type: TYPES.FORGOT_PASSWORD_END,
});

export const forgotPassword = input => dispatch =>
  Promise.all([
    dispatch(forgotPasswordBegin()),
    client.forgotPassword(input).then(
      response => dispatch(forgotPasswordEnd(response)),
      error => console.log(error),
    ),
  ]);

const resetPasswordBegin = () => ({
  type: TYPES.RESET_PASSWORD_BEGIN,
});

const resetPasswordEnd = payload => ({
  type: TYPES.RESET_PASSWORD_END,
  payload,
});

export const resetPassword = input => dispatch =>
  Promise.all([
    dispatch(resetPasswordBegin()),
    client.resetPassword(input).then(
      response => dispatch(resetPasswordEnd(response)),
      error => console.log(error),
    ),
  ]);

export const deleteNotificationBegin = payload => ({
  type: TYPES.DELETE_NOTIFICATION_BEGIN,
  payload,
});

export const deleteNotificationEnd = payload => ({
  type: TYPES.DELETE_NOTIFICATION_END,
  payload,
});

export const deleteNotification = id => dispatch =>
  Promise.all([
    dispatch(deleteNotificationBegin(id)),
    client.deleteNotification(id).then(
      () => dispatch(deleteNotificationEnd(id)),
      () => dispatch(deleteNotificationEnd(id)),
      // error => console.log(error),
    ),
  ]);

export const clearMessage = () => ({
  type: TYPES.CLEAR_MESSAGE,
});

export const updateCurrentUserBegin = payload => ({
  type: TYPES.UPDATE_CURRENT_USER_BEGIN,
  payload,
});

export const updateCurrentUserEnd = payload => ({
  type: TYPES.UPDATE_CURRENT_USER_END,
  payload,
});

export const updateCurrentUser = input => dispatch =>
  Promise.all([
    dispatch(updateCurrentUserBegin()),
    client
      .updateCurrentUser(input)
      .then(() => dispatch(updateCurrentUserEnd()), console.log),
  ]);

export const changePasswordBegin = () => ({
  type: TYPES.CHANGE_PASSWORD_BEGIN,
});

export const changePasswordEnd = () => ({
  type: TYPES.CHANGE_PASSWORD_END,
});

export const changePassword = input => dispatch =>
  Promise.all([
    dispatch(changePasswordBegin()),
    client
      .changePassword(input)
      .then(() => dispatch(changePasswordEnd()), console.log),
  ]);
