import React from 'react';
import ReactToggle from 'react-toggle';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Toggle');

function Toggle({ label, ...props }) {
  return (
    <div className={bem()}>
      <div className={bem('label')}>{label}</div>
      <div className={bem('control')}>
        <ReactToggle icons={false} {...props} />
      </div>
    </div>
  );
}

export default Toggle;
