import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';
import { TYPES } from 'common/constants';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistToken = store => next => action => {
  switch (action.type) {
    case TYPES.LOGIN_END:
    case TYPES.REGISTER_END:
    case TYPES.RESET_PASSWORD_END:
      localStorage.setItem('token', action.payload.token);
      break;
    case TYPES.LOGOUT:
      localStorage.removeItem('token');
      break;
    default:
      break;
  }
  return next(action);
};

const store = createStore(
  reducers,
  { token: localStorage.getItem('token') },
  composeEnhancers(applyMiddleware(thunk, persistToken)),
);

export default store;
