import axios from 'axios';

const client = axios.create();

client.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

/**
 * Login
 *
 * @returns {Promise<object>}
 */
export const login = input =>
  client.post('/v1/auth/login', input).then(response => response.data);

/**
 * Register
 *
 * @returns {Promise<object>}
 */
export const register = input =>
  client.post('/v1/auth/register', input).then(response => response.data);

/**
 * Fetch current city
 *
 * @returns {Promise<object>}
 */
export const fetchCurrentCity = () =>
  client.get('/v1/cities/current').then(response => response.data);

/**
 * Fetch current user
 *
 * @returns {Promise<object>}
 */
export const fetchCurrentUser = () =>
  client.get('/v1/users/current').then(response => response.data);

/**
 * Update current user
 *
 * @returns {Promise<object>}
 */
export const updateCurrentUser = input =>
  client.patch('/v1/users/current', input).then(response => response.data);

/**
 * Update current user
 *
 * @returns {Promise<object>}
 */
export const changePassword = input =>
  client
    .patch('/v1/users/current/password', input)
    .then(response => response.data);

/**
 * Fetch notifications
 *
 * @returns {Promise<object>}
 */
export const fetchNotifications = () =>
  client.get('/v1/users/current/notifications').then(response => response.data);

/**
 * Fetch assets
 *
 * @param {object} params
 * @returns {Promise<array>}
 */
export const fetchAssets = params =>
  client.get('/v1/assets', { params }).then(response => response.data);

/**
 * Fetch assets
 *
 * @param {object} params
 * @returns {Promise<array>}
 */
export const fetchVolunteers = params =>
  axios.get('/api/volunteers.json', { params }).then(response => response.data);

/**
 * Adopt
 *
 * @param {number} id
 * @returns {Promise<object>}
 */
export const adopt = id =>
  client.post(`/v1/assets/${id}/adopt`).then(response => response.data);

/**
 * Abandon
 *
 * @param {number} id
 * @returns {Promise<object>}
 */
export const abandon = (id, input) => {
  return client
    .post(`/v1/assets/${id}/abandon`, input)
    .then(response => response.data);
};

/**
 * Fetch asset events
 *
 * @param {number} id
 * @returns {Promise<object>}
 */
export const fetchAssetEvents = id =>
  client.get(`/v1/assets/${id}/events`).then(response => response.data);

/**
 * Submit notification
 *
 * @param {number} id
 * @returns {Promise<object>}
 */
export const submitNotification = (id, input) =>
  client.post(`/v1/assets/${id}/notify`, input).then(response => response.data);

/**
 * Submit notification
 *
 * @param {number} id
 * @returns {Promise<object>}
 */
export const markAsServiced = (id, input) =>
  client
    .post(`/v1/assets/${id}/service`, input)
    .then(response => response.data);

/**
 * Forgot password
 *
 * @param {object} credentials
 * @returns {Promise<object>}
 */
export const forgotPassword = credentials =>
  client
    .post(`/v1/auth/forgot-password`, credentials)
    .then(response => response.data);

/**
 * Reset password
 *
 * @param {object} credentials
 * @returns {Promise<object>}
 */
export const resetPassword = credentials =>
  client
    .post(`/v1/auth/reset-password`, credentials)
    .then(response => response.data);

/**
 * Delete notification
 *
 * @param {object} credentials
 * @returns {Promise<object>}
 */
export const deleteNotification = id =>
  client.delete(`/v1/notifications/${id}`).then(response => response.data);

/**
 * Mark notifications as read
 *
 * @param {object} credentials
 * @returns {Promise<object>}
 */
export const markNotificationsAsRead = id =>
  client
    .post(`/v1/users/current/notifications/read`)
    .then(response => response.data);
