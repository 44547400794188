import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import React from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Message from 'components/Message';

const bem = blockElementModifier('Enticer');

function Enticer({ headline, strapline }) {
  return (
    <div className={bem()}>
      <Message headline={headline} strapline={strapline} />
      <Row>
        <Col xs={6}>
          <Link to={PATHS.LOGIN} className="Button Button--small Button--block">
            Log In
          </Link>
        </Col>
        <Col xs={6}>
          <Link
            to={PATHS.REGISTER}
            className="Button Button--small Button--block"
          >
            Sign Up
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default Enticer;
