import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './Header.scss';
import { selectCurrentCity } from 'store/selectors/root';
import { leftSidebarToggle } from 'store/actions/layout';
import Header from './Header';
import { setPosition } from 'store/actions/root';

const mapStateToProps = state => ({
  currentCity: selectCurrentCity(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onLeftSidebarToggle: leftSidebarToggle,
      onSetPosition: setPosition,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
