import classNames from 'classnames';
import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Icon');

function Icon({ glyph, weight }) {
  return (
    <i className={classNames(bem(), `fa${weight.charAt(0)}`, `fa-${glyph}`)} />
  );
}

export default Icon;
