import { Link } from 'react-router-dom';
import moment from 'moment';
import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Button from 'components/Button';
import Enticer from 'components/Enticer';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Message from 'components/Message';
import Breadcrumb from 'components/Breadcrumb';

const bem = blockElementModifier('AssetNotify');

const messages = [
  'Cleanup needed',
  'Graffitti / Vandalism',
  'Hazardous litter',
  'Flooding',
];

class AssetNotify extends Component {
  state = {
    input: '',
    isSubmitted: false,
  };

  render() {
    const { asset, hasValidToken, onSubmitNotification } = this.props;
    const { isSubmitted } = this.state;
    if (!asset) {
      return <Loader />;
    }

    return (
      <div className={bem()}>
        <Breadcrumb
          label={asset.name}
          to={PATHS.ASSET_SHOW.replace(':id', asset.id)}
        />          
        {isSubmitted ? (
          <>
            <Message
              headline="Thanks for looking out for your city!"
              strapline="We’ve notified the asset owner to clean Catch Basin #54321"
            />
            <Link
              className="Button Button--block Button--small"
              to={PATHS.ASSET_SHOW.replace(':id', asset.id)}
            >
              See Item Details
            </Link>
          </>
        ) : hasValidToken ? (
          <>
            <div className={bem('title')}>Notify Owner</div>
            <div className={bem('subTitle')}>What’s happening?</div>
            <form
              onSubmit={e => {
                e.preventDefault();
                this.setState({ isSubmitted: true });
                if (
                  asset.status === 'Adopted' &&
                  (!asset.last_notified_at ||
                    moment().diff(moment(asset.last_notified_at), 'hours') > 24)
                ) {
                  onSubmitNotification(asset.id, {
                    message: this.state.input,
                  });
                }
              }}
            >
              <div className={bem('choices')}>
                {messages.map(message => (
                  <div key={message} className={bem('choice')}>
                    <Input
                      required
                      type="radio"
                      label={message}
                      checked={message === this.state.input}
                      value={message}
                      onChange={e =>
                        this.setState({ ...this.state, input: message })
                      }
                    />
                  </div>
                ))}
              </div>
              <Button
                size="small"
                label="Submit"
                disabled={!this.state.input}
              />
            </form>
          </>
        ) : (
          <Enticer
            headline="Log in to notify the owner!"
            strapline="You’re one step closer to adopting this catch basin. Just log in or sign up, and we’ll add this asset to your account."
          />
        )}
      </div>
    );
  }
}

export default AssetNotify;
