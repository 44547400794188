import classNames from 'classnames';
import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Input');

function Input({ label, mask, type, warning, ...props }) {
  return (
    <label
      className={classNames(bem(), bem(null, type), {
        [bem(null, 'warning')]: warning,
      })}
    >
      {label && <div className={bem('label')}>{label}</div>}
      <input
        className={bem('control')}
        type={type === 'text' && mask ? 'password' : type}
        {...props}
      />
      <div className={bem(type)} />
      {warning && <div className={bem('warning')}>{warning}</div>}
    </label>
  );
}

export default Input;
