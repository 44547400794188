import { Link } from 'react-router-dom';
import moment from 'moment';
import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Enticer from 'components/Enticer';
import Loader from 'components/Loader';
import Message from 'components/Message';
import Breadcrumb from 'components/Breadcrumb';

const bem = blockElementModifier('AssetService');

class AssetService extends Component {
  componentDidMount() {
    const { asset } = this.props;
    if (asset) {
      this.handleOnAdopt(asset);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { asset } = this.props;
    if (!asset && nextProps.asset) {
      this.handleOnAdopt(nextProps.asset);
    }
  }

  handleOnAdopt(asset) {
    const { onMarkAsService } = this.props;
    if (
      asset.status === 'Yours' &&
      (!asset.last_serviced_at ||
        moment().diff(moment(asset.last_serviced_at), 'hours') > 24)
    ) {
      onMarkAsService(asset.id);
    }
  }

  render() {
    const { asset, hasValidToken } = this.props;

    if (!asset) {
      return <Loader />;
    }

    return (
      <div className={bem()}>
        <Breadcrumb
          label={asset.name}
          to={PATHS.ASSET_SHOW.replace(':id', asset.id)}
        />
          
        {hasValidToken ? (
          <>
            <Message
              headline="Thank you!"
              strapline="The city appreciates your help in keeping Vancouver green. We’ve marked this asset as serviced in your maintenance log."
            />
            <Link
              className="Button Button--block Button--small"
              to={PATHS.ASSET_SHOW.replace(':id', asset.id)}
            >
              See Item Details
            </Link>
          </>
        ) : (
          <Enticer
            headline="Log in to adopt this catch basin!"
            strapline="You’re one step closer to adopting this catch basin. Just log in or sign up, and we’ll add this asset to your account."
          />
        )}
      </div>
    );
  }
}

export default AssetService;
