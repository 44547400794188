import './Filters.scss';
import Filters from './Filters';
import {
  selectAssetStatuses,
  selectStatusWithInitialValue,
  selectType,
  selectAssetTypes,
  selectSortBy,
} from 'store/selectors/canvas';
import {
  setType,
  setStatus,
  setSortBy,
  resetFilters,
} from 'store/actions/canvas';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => ({
  statuses: selectAssetStatuses(state),
  astatus: selectStatusWithInitialValue(state),
  atype: selectType(state),
  types: selectAssetTypes(state),
  sortBy: selectSortBy(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSetType: setType,
      onSetStatus: setStatus,
      onSetSortBy: setSortBy,
      onResetFilters: resetFilters,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
