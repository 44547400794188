import { Grid } from 'react-flexbox-grid';
import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Button from 'components/Button';
import Field from 'components/Field';
import Input from 'components/Input';
import LayoutWithSplit from 'components/LayoutWithSplit';
import ReverseGeocode from 'components/ReverseGeocode';
import Select from 'components/Select';
import Toast from 'components/Toast';
import Geolookup from 'components/Geolookup';

const bem = blockElementModifier('Volunteer');

const services = [
  'Grocery shopping and delivery',
  'Prescription pickup and delivery',
  'Meal preparation and delivery',
  'Phone and/or virtual visits',
  'Dog walking',
  'Laundry services',
];

class Volunteer extends Component {
  state = {
    input: {
      lat: 49.260774,
      lng: -123.113952,
      is_volunteer: false,
    },
    warnings: {},
  };

  componentDidMount() {
    const { onClearMessage } = this.props;
    onClearMessage();
  }

  setInput(nextInput) {
    const { input, ...state } = this.state;
    this.setState({
      ...state,
      input: {
        ...nextInput,
      },
    });
  }

  handleOnNext() {
    const { onUpdateCurrentUser, history } = this.props;
    onUpdateCurrentUser(this.state.input);
    history.push(PATHS.ROOT);
  }

  render() {
    const { message } = this.props;
    const { input } = this.state;
    return (
      <LayoutWithSplit
        register
      >
        <div className={bem()}>
          <Grid fluid>
            <div className={bem('headline')}>Thanks for signing up!</div>
            <div className={bem('strapline')}>
              Would you like volunteer and help provide essential services to
              people in need?
            </div>
            {message && <Toast message={message} />}
          </Grid>
          <Grid fluid>
            <form
              onSubmit={e => {
                e.preventDefault();
                this.handleOnNext(e);
              }}
            >
              <Field>
                <Input
                  label="Yes, I would like to volunteer"
                  type="checkbox"
                  checked={input.is_volunteer}
                  onChange={e =>
                    this.setInput({
                      ...input,
                      is_volunteer: !input.is_volunteer,
                    })
                  }
                />
              </Field>
              {input.is_volunteer && (
                <>
                  <Field
                    label="Services"
                    hint="Select ALL services you are offering"
                  >
                    {services.map(service => (
                      <Input
                        type="checkbox"
                        label={service}
                        onChange={e => {
                          this.setInput({ ...input, services: [service] });
                        }}
                      />
                    ))}
                  </Field>
                  <Field label="Preferred Distance">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <Select
                          options={['1', '5', '20']}
                          value={input.distance}
                          onChange={e =>
                            this.setInput({
                              ...input,
                              distance: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div style={{ padding: '0 10px' }}>km from</div>
                      <div style={{ flex: 1 }}>
                        <Geolookup
                          onChange={e => {
                            this.setInput({
                              ...input,
                              lat: e.latlng.lat,
                              lng: e.latlng.lng,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Field>
                  <Field>
                    <ReverseGeocode
                      onChange={e =>
                        this.setInput({
                          ...input,
                          lat: e.latlng.lat,
                          lng: e.latlng.lng,
                        })
                      }
                      position={{ lat: input.lat, lng: input.lng }}
                    />
                  </Field>
                </>
              )}
              <Button block label="Finish" />
            </form>
          </Grid>
        </div>
      </LayoutWithSplit>
    );
  }
}

export default Volunteer;
