import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Toast');

function Toast({ message }) {
  return <div className={bem()}>{message}</div>;
}

export default Toast;
