import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';
import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Breadcrumb');


function Breadcrumb({ label, ...props }) {
  return (
    <Link className={bem()} {...props}>
      <Icon glyph="angle-left" weight="solid" />
      {label}
    </Link>
  );
}

export default Breadcrumb;
