import React from 'react';

import { blockElementModifier } from 'common/helpers';
import { STATUS_COLOR_MAP } from 'common/constants';
import Indicator from 'components/Indicator';

const bem = blockElementModifier('Status');

function Status({ status }) {
  return (
    <div className={bem()}>
      <Indicator size="small" color={STATUS_COLOR_MAP[status]} />
      <div className={bem('label')}>{status}</div>
    </div>
  );
}

export default Status;
