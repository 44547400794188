import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './AssetAdopt.scss';
import AssetAdopt from './AssetAdopt';
import { selectAssetById } from 'store/selectors/canvas';
import { selectHasValidToken } from 'store/selectors/root';
import { adopt } from 'store/actions/popup';

const mapStateToProps = (state, ownProps) => ({
  asset: selectAssetById(state, ownProps.match.params.id),
  hasValidToken: selectHasValidToken(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onAdopt: adopt,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssetAdopt);
