import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './Login.scss';
import { login, clearMessage } from 'store/actions/root';
import { selectHasValidToken, selectMessage } from 'store/selectors/root';
import Login from './Login';

const mapStateToProps = state => ({
  hasValidToken: selectHasValidToken(state),
  message: selectMessage(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onClearMessgae: clearMessage,
      onLogin: login,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
