import { TYPES } from 'common/constants';
import * as client from 'services/client';

export const reset = payload => ({
  type: TYPES.RESET,
  payload,
});

export const adoptBegin = payload => ({
  type: TYPES.ADOPT_BEGIN,
  payload,
});

export const adoptEnd = payload => ({
  type: TYPES.ADOPT_END,
  payload,
});

export const adopt = payload => dispatch =>
  Promise.all([
    dispatch(adoptBegin(payload)),
    client.adopt(payload).then(
      response => dispatch(adoptEnd(payload)),
      error => console.log(error),
    ),
  ]);

const markAsServicedBegin = payload => ({
  type: TYPES.MARK_AS_SERVICED_BEGIN,
  payload,
});

const markAsServicedEnd = payload => ({
  type: TYPES.MARK_AS_SERVICED_END,
  payload,
});

export const markAsServiced = payload => dispatch =>
  Promise.all([
    dispatch(markAsServicedBegin(payload)),
    client.markAsServiced(payload).then(
      response => dispatch(markAsServicedEnd(payload)),
      error => console.log(error),
    ),
  ]);

export const notifyOwner = payload => ({
  type: TYPES.NOTIFY_OWNER_END,
  payload,
});

export const submitNotificationBegin = payload => ({
  type: TYPES.SUBMIT_NOTIFICATION_BEGIN,
  payload,
});

export const submitNotificationEnd = payload => ({
  type: TYPES.SUBMIT_NOTIFICATION_END,
  payload,
});

export const submitNotification = (id, input) => dispatch =>
  Promise.all([
    dispatch(submitNotificationBegin(id)),
    client.submitNotification(id, input).then(
      response => dispatch(submitNotificationEnd(id)),
      error => console.log(error),
    ),
  ]);

export const loginRequired = payload => ({
  type: TYPES.LOGIN_REQUIRED,
  payload,
});

const abandonSubmitBegin = () => ({
  type: TYPES.ABANDON_SUBMIT_BEGIN,
});

const abandonSubmitEnd = payload => ({
  type: TYPES.ABANDON_SUBMIT_END,
  payload,
});

export const abandonSubmit = (id, input) => dispatch =>
  Promise.all([
    dispatch(abandonSubmitBegin()),
    client.abandon(id, input).then(
      response => dispatch(abandonSubmitEnd(id)),
      error => console.log(error),
    ),
  ]);

export const abandon = payload => ({
  type: TYPES.ABANDON,
  payload,
});
