import { Grid } from 'react-flexbox-grid';
import { Redirect, Link } from 'react-router-dom';
import React, { Component } from 'react';
import validate from 'validate.js';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Button from 'components/Button';
import Field from 'components/Field';
import Input from 'components/Input';
import LayoutWithSplit from 'components/LayoutWithSplit';
import Toast from 'components/Toast';

const bem = blockElementModifier('ForgotPassword');

class ForgotPassword extends Component {
  state = {
    input: {
      email: '',
    },
    warnings: {},
  };

  componentDidMount() {
    const { onClearMessage } = this.props;
    onClearMessage();
  }

  handleOnSubmit(e) {
    e.preventDefault();
    const { input } = this.state;
    const { onForgotPassword } = this.props;
    const schema = {
      email: { email: true },
    };
    const warnings = validate(input, schema);
    if (warnings) {
      this.setState({ warnings });
    } else {
      onForgotPassword(input);
      this.setState({
        message:
          'If the given email exists in our application, you will recieve instructions by email for resetting your password',
        warnings: {},
      });
    }
  }

  render() {
    const { hasValidToken } = this.props;
    const { input, warnings, message } = this.state;

    if (hasValidToken) {
      return <Redirect to={PATHS.ROOT} />;
    }

    return (
      <LayoutWithSplit
        register
      >
        <div className={bem()}>
          <Grid fluid>
            <div className={bem('headline')}>Forgot password</div>
            {message && <Toast message={message} />}
            <form onSubmit={e => this.handleOnSubmit(e)}>
              <Field>
                <Input
                  label="Email"
                  type="email"
                  value={input.email}
                  warning={warnings.email}
                  onChange={e =>
                    this.setState({
                      ...this.state,
                      input: { ...input, email: e.target.value },
                    })
                  }
                  autocapitalize="none"
                  required
                />
                <div className={bem('hint')}>
                  <Link to={PATHS.LOGIN}>Remember your password?</Link>
                </div>
              </Field>
              <Button block label="Forgot Password" />
            </form>
          </Grid>
        </div>
      </LayoutWithSplit>
    );
  }
}

export default ForgotPassword;
