import React from 'react';
import ReactPaginate from 'react-paginate';

import { blockElementModifier } from 'common/helpers';
import Icon from 'components/Icon';

const bem = blockElementModifier('Paginate');

function Paginate({ total, page, perPage, ...props }) {
  return Math.ceil(total / perPage) > 1 ? (
    <div className={bem()}>
      <ReactPaginate
        {...props}
        pageCount={Math.ceil(total / perPage)}
        containerClassName={bem('wrapper')}
        pageClassName={bem('page')}
        previousClassName={bem('previous')}
        breakClassName={bem('break')}
        nextClassName={bem('next')}
        activeClassName={bem(null, 'active')}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel={<Icon glyph="angle-left" weight="regular" />}
        nextLabel={<Icon glyph="angle-right" weight="regular" />}
      />
      <div className={bem('total')}>
        {page + 1} of {Math.ceil(total / perPage)} ({total})
      </div>
    </div>
  ) : null;
}

export default Paginate;
