import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './AssetAbandon.scss';
import AssetAbandon from './AssetAbandon';
import { selectAssetById } from 'store/selectors/canvas';
import { abandonSubmit } from 'store/actions/popup';

const mapStateToProps = (state, ownProps) => ({
  asset: selectAssetById(state, ownProps.match.params.id),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onAbandonSubmit: abandonSubmit,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssetAbandon);
