import { Grid, Row, Col } from 'react-flexbox-grid';
import React, { Component } from 'react';
import validate from 'validate.js';

import { blockElementModifier } from 'common/helpers';
import Button from 'components/Button';
import Editable from 'components/Editable';
import Field from 'components/Field';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Panel from 'components/Panel';
import Toggle from 'components/Toggle';

const bem = blockElementModifier('Profile');

class Profile extends Component {
  state = {
    input: null,
    warnings: {},
  };

  componentDidMount() {
    const { onGetCurrentUser, currentUser } = this.props;
    if (currentUser) {
      this.setState({
        input: currentUser,
      });
    } else {
      onGetCurrentUser();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if (prevProps.currentUser !== currentUser) {
      this.setState({
        input: currentUser,
      });
    }
  }

  render() {
    const { onUpdateCurrentUser, onClose, onChangePassword } = this.props;
    const { input, warnings } = this.state;
    if (input) {
      return (
        <div className={bem()}>
          <div className={bem('header')}>
            <div className={bem('title')}>Profile</div>
            <button type="button" onClick={onClose} className={bem('close')}>
              <Icon glyph="times" weight="regular" />
              Close
            </button>
          </div>
          <div className={bem('wrapper')}>
            <Grid fluid>
              <Row>
                <Col xs={12} md={5}>
                  <Panel>
                    <div className={bem('headline')}>Your privacy matters</div>
                    <div className={bem('strapline')}>
                      The City of Vancouver cares about your privacy. Read
                      through our Terms of Service and Privacy Policy to
                      understand how we manage your information.
                    </div>
                  </Panel>
                </Col>
                <Col md={6} mdOffset={1} xs={12}>
                  <div className={bem('section')}>
                    <div className={bem('headline')}>Personal Information</div>
                    <Editable
                      label="Name"
                      value={`${input.first_name} ${input.last_name}`}
                    >
                      {setIsOpen => (
                        <form
                          onSubmit={e => {
                            e.preventDefault();
                            onUpdateCurrentUser({
                              first_name: input.first_name,
                              last_name: input.last_name,
                            });
                            setIsOpen(false);
                          }}
                        >
                          <Row>
                            <Col xs={6}>
                              <Field>
                                <Input
                                  required
                                  type="text"
                                  label="First name"
                                  value={input.first_name}
                                  onChange={e =>
                                    this.setState({
                                      input: {
                                        ...input,
                                        first_name: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </Field>
                            </Col>
                            <Col xs={6}>
                              <Field>
                                <Input
                                  required
                                  type="text"
                                  label="Last name"
                                  value={input.last_name}
                                  onChange={e =>
                                    this.setState({
                                      input: {
                                        ...input,
                                        last_name: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </Field>
                            </Col>
                          </Row>
                          <Button label="Save" />
                        </form>
                      )}
                    </Editable>
                    <Editable
                      label="Address"
                      value={`${input.address}, ${input.city}, ${input.state} ${input.zip}`}
                    >
                      {setIsOpen => (
                        <form
                          onSubmit={e => {
                            e.preventDefault();
                            onUpdateCurrentUser({
                              address: input.address,
                              city: input.city,
                              state: input.state,
                              zip: input.zip,
                            });
                            setIsOpen(false);
                          }}
                        >
                          <Field>
                            <Input
                              required
                              type="text"
                              label="Street Address"
                              value={input.address}
                              onChange={e =>
                                this.setState({
                                  input: {
                                    ...input,
                                    address: e.target.value,
                                  },
                                })
                              }
                            />
                          </Field>
                          <Row>
                            <Col xs={4}>
                              <Field>
                                <Input
                                  required
                                  type="text"
                                  label="City"
                                  value={input.city}
                                  onChange={e =>
                                    this.setState({
                                      input: {
                                        ...input,
                                        city: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </Field>
                            </Col>
                            <Col xs={3}>
                              <Field>
                                <Input
                                  required
                                  type="text"
                                  label="Province"
                                  value={input.state}
                                  onChange={e =>
                                    this.setState({
                                      input: {
                                        ...input,
                                        state: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </Field>
                            </Col>
                            <Col xs={5}>
                              <Field>
                                <Input
                                  required
                                  type="text"
                                  label="Postal Code"
                                  value={input.zip}
                                  onChange={e =>
                                    this.setState({
                                      input: {
                                        ...input,
                                        zip: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </Field>
                            </Col>
                          </Row>
                          <Button label="Save" />
                        </form>
                      )}
                    </Editable>
                    <Editable label="Phone" value={input.phone}>
                      {setIsOpen => (
                        <form
                          onSubmit={e => {
                            e.preventDefault();
                            onUpdateCurrentUser({ phone: input.phone });

                            setIsOpen(false);
                          }}
                        >
                          <Field>
                            <Input
                              required
                              type="tel"
                              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                              value={input.phone}
                              onChange={e =>
                                this.setState({
                                  input: { ...input, phone: e.target.value },
                                })
                              }
                            />
                          </Field>
                          <Button label="Save" />
                        </form>
                      )}
                    </Editable>
                  </div>
                  <div className={bem('section')}>
                    <div className={bem('headline')}>Settings</div>
                    <Editable label="Email" value={input.email}>
                      {setIsOpen => (
                        <form
                          onSubmit={e => {
                            e.preventDefault();
                            const schema = {
                              email: { email: true },
                            };
                            const warnings = validate(input, schema);
                            if (warnings) {
                              this.setState({ warnings });
                            } else {
                              onUpdateCurrentUser({ email: input.email });
                              this.setState({ warnings: {} });
                              setIsOpen(false);
                            }
                          }}
                        >
                          <Field>
                            <Input
                              required
                              type="email"
                              value={input.email}
                              warning={warnings.email}
                              autocapitalize="none"
                              onChange={e =>
                                this.setState({
                                  input: { ...input, email: e.target.value },
                                })
                              }
                            />
                          </Field>
                          <Button label="Save" />
                        </form>
                      )}
                    </Editable>
                    <Editable label="Password" value="*****">
                      {setIsOpen => (
                        <form
                          onSubmit={e => {
                            e.preventDefault();
                            const schema = {
                              old_password: { length: { minimum: 6 } },
                              new_password: { length: { minimum: 6 } },
                              confirm_password: {
                                equality: 'new_password',
                              },
                            };
                            const warnings = validate(input, schema);
                            if (warnings) {
                              this.setState({ warnings });
                            } else {
                              onChangePassword({
                                old_password: input.old_password,
                                new_password: input.new_password,
                                confirm_password: input.confirm_password,
                              });
                              this.setState({ warnings: {} });
                              setIsOpen(false);
                            }
                          }}
                        >
                          <Field>
                            <Input
                              required
                              type="text"
                              label="Old Password"
                              value={input.old_password}
                              warning={warnings.old_password}
                              onChange={e =>
                                this.setState({
                                  input: {
                                    ...input,
                                    old_password: e.target.value,
                                  },
                                })
                              }
                              mask
                            />
                          </Field>
                          <Field>
                            <Input
                              required
                              type="text"
                              label="New Password"
                              value={input.new_password}
                              warning={warnings.new_password}
                              onChange={e =>
                                this.setState({
                                  input: {
                                    ...input,
                                    new_password: e.target.value,
                                  },
                                })
                              }
                              mask
                            />
                          </Field>
                          <Field>
                            <Input
                              required
                              type="text"
                              label="Confirm Password"
                              value={input.confirm_password}
                              warning={warnings.confirm_password}
                              onChange={e =>
                                this.setState({
                                  input: {
                                    ...input,
                                    confirm_password: e.target.value,
                                  },
                                })
                              }
                              mask
                            />
                          </Field>
                          <Button label="Save" />
                        </form>
                      )}
                    </Editable>
                    <div className="Editable">
                      <Toggle
                        label="Volunteer Status"
                        defaultChecked={input.is_volunteer}
                        onChange={e => {
                          onUpdateCurrentUser({
                            is_volunteer: !input.is_volunteer,
                          });
                          this.setState({
                            input: {
                              ...input,
                              is_volunteer: !input.is_volunteer,
                            },
                          });
                        }}
                      />
                      <div className={bem('hint')}>
                        Enabling this means you are applying for other programs
                        such as Snow Angel
                      </div>
                    </div>
                  </div>
                  <div className={bem('section')}>
                    <div className={bem('headline')}>Privacy</div>
                    <div className="Editable">
                      <Toggle
                        label="Set status to private"
                        defaultChecked={input.is_private}
                        onChange={e => {
                          onUpdateCurrentUser({
                            is_private: !input.is_private,
                          });
                          this.setState({
                            input: { ...input, is_private: !input.is_private },
                          });
                        }}
                      />
                      <div className={bem('hint')}>
                        Turning this off means your name will be displayed in
                        listings and search results
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      );
    }
    return <div>Loading</div>;
  }
}

export default Profile;
