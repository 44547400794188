import { Grid } from 'react-flexbox-grid';
import { Link, Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import validate from 'validate.js';

import { blockElementModifier } from 'common/helpers';
import Input from 'components/Input';
import Button from 'components/Button';
import { PATHS } from 'common/constants';
import Field from 'components/Field';
import LayoutWithSplit from 'components/LayoutWithSplit';
import Toast from 'components/Toast';

const bem = blockElementModifier('Login');

class Login extends Component {
  state = {
    input: {
      email: '',
      password: '',
    },
    warnings: {},
  };

  componentDidMount() {
    const { onClearMessgae } = this.props;
    onClearMessgae();
  }

  handleOnSubmit(e) {
    e.preventDefault();
    const { onLogin } = this.props;
    const { input } = this.state;
    const schema = {
      email: { email: true },
      password: { length: { minimum: 6 } },
    };
    const warnings = validate(input, schema);
    if (warnings) {
      this.setState({ warnings });
    } else {
      onLogin(input);
    }
  }

  render() {
    const { message, hasValidToken } = this.props;

    if (hasValidToken) {
      return <Redirect to={PATHS.ROOT} />;
    }

    return (
      <LayoutWithSplit
        login
      >
        <Grid fluid className={bem()}>
          <form className={bem('form')} onSubmit={e => this.handleOnSubmit(e)}>
            <Field>
              <div className={bem('headline')}>Log In</div>
              {message && <Toast message={message} />}
              <Input
                label="Email"
                type="email"
                value={this.state.email}
                warning={this.state.warnings.email}
                onChange={e =>
                  this.setState({
                    ...this.state,
                    input: { ...this.state.input, email: e.target.value },
                  })
                }
                autocapitalize="none"
                required
              />
            </Field>
            <Field>
              <Input
                label="Password"
                type="text"
                value={this.state.password}
                warning={this.state.warnings.password}
                onChange={e =>
                  this.setState({
                    ...this.state,
                    input: { ...this.state.input, password: e.target.value },
                  })
                }
                required
                mask
              />
              <div className={bem('hint')}>
                <Link to={PATHS.FORGOT_PASSWORD}>Forgot password?</Link>
              </div>
            </Field>
            <Button block label="Log In" />
          </form>
        </Grid>
      </LayoutWithSplit>
    );
  }
}

export default Login;
