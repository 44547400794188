import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './Profile.scss';
import Profile from './Profile';
import { selectCurrentUser } from 'store/selectors/root';
import {
  getCurrentUser,
  updateCurrentUser,
  changePassword,
} from 'store/actions/root';

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onGetCurrentUser: getCurrentUser,
      onUpdateCurrentUser: updateCurrentUser,
      onChangePassword: changePassword,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
