import React, { Component } from 'react';
import moment from 'moment';

import { blockElementModifier } from 'common/helpers';
import Icon from 'components/Icon';

const bem = blockElementModifier('Notifications');

class Notifications extends Component {
  componentDidMount() {
    const { unreadNotifications, onMarkNotificationsAsRead } = this.props;
    if (unreadNotifications.length > 0) {
      onMarkNotificationsAsRead();
    }
  }

  render() {
    const {
      notifications,
      notificationsFromBeforeToday,
      notificationsFromToday,
      onBlurAsset,
      onFocusAsset,
      onDeleteNotification,
      onRightSidebarToggle,
      onSetPosition,
    } = this.props;
    return (
      <div className={bem()}>
        <div className={bem('body')}>
          {notifications.length > 0 ? (
            <div className={bem('content')}>
              <div className={bem('header')}>
                <span>Notifications ({notifications.length})</span>
                <button className={bem('close')} onClick={onRightSidebarToggle}>
                  <Icon weight="light" glyph="times" />
                </button>
              </div>
              {notificationsFromToday.length > 0 && (
                <>
                  <div className={bem('heading')}>Today</div>
                  <div className={bem('items')}>
                    {notificationsFromToday.map(notification => (
                      <div
                        key={notification.id}
                        className={bem('item')}
                        onMouseEnter={() => onFocusAsset(notification.asset.id)}
                        onMouseLeave={() => onBlurAsset(notification.asset.id)}
                        onClick={e =>
                          onSetPosition({
                            latitude: notification.asset.position[0],
                            longitude: notification.asset.position[1],
                          })
                        }
                      >
                        <div className={bem('details')}>
                          <div className={bem('id')}>
                            {notification.asset.name}
                          </div>
                          <div className={bem('date')}>
                            {moment(notification.created_at).fromNow()}
                          </div>
                        </div>
                        <div className={bem('message')}>
                          <div className={bem('messageBody')}>
                            {notification.message}
                          </div>
                          <button
                            className={bem('button')}
                            onClick={e => {
                              e.stopPropagation();
                              onDeleteNotification(notification.id);
                            }}
                          >
                            <Icon glyph="times" weight="solid" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {notificationsFromBeforeToday.length > 0 && (
                <>
                  <div className={bem('heading')}>Past 7 Days</div>
                  <div className={bem('items')}>
                    {notificationsFromBeforeToday.map(notification => (
                      <div
                        key={notification.id}
                        className={bem('item')}
                        onMouseEnter={() => onFocusAsset(notification.asset.id)}
                        onMouseLeave={() => onBlurAsset(notification.asset.id)}
                        onClick={e =>
                          onSetPosition({
                            latitude: notification.asset.position[0],
                            longitude: notification.asset.position[1],
                          })
                        }
                      >
                        <div className={bem('details')}>
                          <div className={bem('id')}>
                            {notification.asset.name}
                          </div>
                          <div className={bem('date')}>
                            {moment(notification.created_at).fromNow()}
                          </div>
                        </div>
                        <div className={bem('message')}>
                          <div className={bem('messageBody')}>
                            {notification.message}
                          </div>
                          <button
                            className={bem('button')}
                            onClick={e => {
                              e.stopPropagation();
                              onDeleteNotification(notification.id);
                            }}
                          >
                            <Icon glyph="times" weight="solid" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={bem('empty')}>Nothing to see here</div>
          )}
        </div>
      </div>
    );
  }
}

export default Notifications;
