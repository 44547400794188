import { Switch, Route } from 'react-router-dom';
import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import AssetAbandon from 'components/AssetAbandon';
import AssetAdopt from 'components/AssetAdopt';
import AssetCanvas from 'components/AssetCanvas';
import AssetNotify from 'components/AssetNotify';
import AssetService from 'components/AssetService';
import AssetShow from 'components/AssetShow';
import AssetsList from 'components/AssetList';
import LayoutWithSidebar from 'components/LayoutWithSidebar';
import Loader from 'components/Loader';
import Notifications from 'components/Notifications';

const bem = blockElementModifier('Assets');

class Assets extends Component {
  componentDidMount() {
    const {
      onGetCurrentUser,
      onGetNotifications,
      onGetCurrentPosition,
      hasValidToken,
    } = this.props;
    onGetCurrentPosition();
    if (hasValidToken) {
      onGetCurrentUser();
      onGetNotifications();
    }
  }

  render() {
    const { currentCity, currentPosition, onGetAssets, ...props } = this.props;
    if (currentCity) {
      return (
        <div className={bem()}>
          <LayoutWithSidebar
            expand={!!props.match.params.id}
            leftSidebar={
              <Switch>
                <Route path={PATHS.ASSET_ABANDON} component={AssetAbandon} />
                <Route path={PATHS.ASSET_SERVICE} component={AssetService} />
                <Route path={PATHS.ASSET_NOTIFY} component={AssetNotify} />
                <Route path={PATHS.ASSET_ADOPT} component={AssetAdopt} />
                <Route path={PATHS.ASSET_SHOW} component={AssetShow} />
                <Route path={PATHS.ASSET_LIST} component={AssetsList} />
              </Switch>
            }
            rightSidebar={<Notifications />}
          >
            <AssetCanvas
              {...props}
              onGetItems={onGetAssets}
              latitude={
                currentPosition
                  ? currentPosition.latitude
                  : currentCity.position.latitude
              }
              longitude={
                currentPosition
                  ? currentPosition.longitude
                  : currentCity.position.longitude
              }
            />
          </LayoutWithSidebar>
        </div>
      );
    }
    return <Loader />;
  }
}

export default Assets;
