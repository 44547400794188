import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './LayoutWithSidebar.scss';
import {
  selectLeftSidebarIsOpen,
  selectRightSidebarIsOpen,
} from 'store/selectors/layout';
import LayoutWithSidebar from './LayoutWithSidebar';
import { resizeLayout, leftSidebarToggle } from 'store/actions/layout';
import { selectHasValidToken, selectCurrentCity } from 'store/selectors/root';
import { selectViewableAsset } from 'store/selectors/canvas';

const mapStateToProps = state => ({
  hasValidToken: selectHasValidToken(state),
  isLeftSidebarOpen: selectLeftSidebarIsOpen(state),
  isRightSidebarOpen: selectRightSidebarIsOpen(state),
  currentCity: selectCurrentCity(state),
  viewableAsset: selectViewableAsset(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onLeftSidebarToggle: leftSidebarToggle,
      onResizeLayout: resizeLayout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LayoutWithSidebar);
