import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './Notifications.scss';
import { focusAsset, blurAsset } from 'store/actions/canvas';
import { setPosition, deleteNotification } from 'store/actions/root';
import {
  selectNotifications,
  selectNotificationsFromToday,
  selectNotificationsFromBeforeToday,
  selectUnreadNotifications,
} from 'store/selectors/root';
import Notifications from './Notifications';
import {
  rightSidebarToggle,
  markNotificationsAsRead,
} from 'store/actions/layout';

const mapStateToProps = state => ({
  notifications: selectNotifications(state),
  notificationsFromToday: selectNotificationsFromToday(state),
  notificationsFromBeforeToday: selectNotificationsFromBeforeToday(state),
  unreadNotifications: selectUnreadNotifications(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onBlurAsset: blurAsset,
      onFocusAsset: focusAsset,
      onRightSidebarToggle: rightSidebarToggle,
      onDeleteNotification: deleteNotification,
      onMarkNotificationsAsRead: markNotificationsAsRead,
      onSetPosition: setPosition,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
