import { connect } from 'react-redux';

import './Welcome.scss';
import Welcome from './Welcome';
import { selectCurrentCity, selectHasValidToken } from 'store/selectors/root';

const mapStateToProps = state => ({
  currentCity: selectCurrentCity(state),
  hasValidToken: selectHasValidToken(state),
});

export default connect(mapStateToProps)(Welcome);
