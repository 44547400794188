export const PATHS = {
  ASSET_SHOW: '/assets/:id',
  ASSET_LIST: '/assets/:id?',
  ASSET_ROOT: '/assets',
  ASSET_NOTIFY: '/assets/:id/notify',
  ASSET_ABANDON: '/assets/:id/abandon',
  ASSET_ADOPT: '/assets/:id/adopt',
  ASSET_SERVICE: '/assets/:id/service',
  COMPLETE_PROFILE: '/auth/complete-profile',
  FORGOT_PASSWORD: '/auth/forgot-password',
  LOGIN: '/auth/login',
  PROFILE: '/profile',
  REGISTER: '/auth/register',
  RESET_PASSWORD: '/auth/reset-password',
  ROOT: '/',
  VOLUNTEER_CONTACT: '/volunteers/:id/contact',
  VOLUNTEER_SHOW: '/volunteers/:id',
  VOLUNTEER: '/auth/volunteer',
  VOLUNTEER_LIST: '/volunteers/:id?',
  VOLUNTEER_ROOT: '/volunteers',
};

export const TYPES = {
  GET_VOLUNTEERS_BEGIN: 'GET_VOLUNTEERS_BEGIN',
  GET_VOLUNTEERS_END: 'GET_VOLUNTEERS_END',
  ABANDON_SUBMIT_BEGIN: 'ABANDON_SUBMIT_BEGIN',
  ABANDON_SUBMIT_END: 'ABANDON_SUBMIT_END',
  ABANDON: 'ABANDON',
  ADOPT_BEGIN: 'ADOPT_BEGIN',
  ADOPT_END: 'ADOPT_END',
  BLUR_ASSET: 'BLUR_ASSET',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  SET_IS_MENU_OPEN: 'SET_IS_MENU_OPEN',
  CHANGE_PASSWORD_BEGIN: 'CHANGE_PASSWORD_BEGIN',
  CHANGE_PASSWORD_END: 'CHANGE_PASSWORD_END',
  DELETE_NOTIFICATION_BEGIN: 'DELETE_NOTIFICATION_BEGIN',
  DELETE_NOTIFICATION_END: 'DELETE_NOTIFICATION_END',
  FOCUS_ASSET: 'FOCUS_ASSET',
  FORGOT_PASSWORD_BEGIN: 'FORGOT_PASSWORD_BEGIN',
  FORGOT_PASSWORD_END: 'FORGOT_PASSWORD_END',
  GET_ASSET_EVENTS_BEGIN: 'GET_ASSET_EVENTS_BEGIN',
  GET_ASSET_EVENTS_END: 'GET_ASSET_EVENTS_END',
  GET_ASSETS_BEGIN: 'GET_ASSETS_BEGIN',
  GET_ASSETS_END: 'GET_ASSETS_END',
  GET_CURRENT_CITY_BEGIN: 'GET_CURRENT_CITY_BEGIN',
  GET_CURRENT_CITY_END: 'GET_CURRENT_CITY_END',
  GET_CURRENT_POSITION_BEGIN: 'GET_CURRENT_POSITION_BEGIN',
  GET_CURRENT_POSITION_END: 'GET_CURRENT_POSITION_END',
  GET_CURRENT_USER_BEGIN: 'GET_CURRENT_USER_BEGIN',
  GET_CURRENT_USER_END: 'GET_CURRENT_USER_END',
  GET_NOTIFICATIONS_BEGIN: 'GET_NOTIFICATIONS_BEGIN',
  GET_NOTIFICATIONS_END: 'GET_NOTIFICATIONS_END',
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_END: 'LOGIN_END',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_REQUIRED: 'LOGIN_REQUIRED',
  LOGOUT: 'LOGOUT',
  MARK_AS_SERVICED_BEGIN: 'MARK_AS_SERVICED_BEGIN',
  MARK_AS_SERVICED_END: 'MARK_AS_SERVICED_END',
  MARK_NOTIFICATION_AS_READ_BEGIN: 'MARK_NOTIFICATION_AS_READ_BEGIN',
  MARK_NOTIFICATION_AS_READ_END: 'MARK_NOTIFICATION_AS_READ_END',
  NOTIFY_OWNER_END: 'NOTIFY_OWNER_END',
  POPUP_CLOSE: 'POPUP_CLOSE',
  POPUP_OPEN: 'POPUP_OPEN',
  REGISTER_BEGIN: 'REGISTER_BEGIN',
  REGISTER_END: 'REGISTER_END',
  REGISTER_ERROR: 'REGISTER_ERROR',
  RESET_FILTERS: 'RESET_FILTERS',
  RESET_PASSWORD_BEGIN: 'RESET_PASSWORD_BEGIN',
  RESET_PASSWORD_END: 'RESET_PASSWORD_END',
  RESET: 'RESET',
  RESIZE_LAYOUT_DONE: 'RESIZE_LAYOUT_DONE',
  RESIZE_LAYOUT: 'RESIZE_LAYOUT',
  SET_POSITION: 'SET_POSITION',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_STATUS: 'SET_STATUS',
  SET_TYPE: 'SET_TYPE',
  SET_SERVICE_FILTERS: 'SET_SERVICE_FILTERS',
  RESET_SERVICE_FILTERS: 'RESET_SERVICE_FILTERS',
  SUBMIT_NOTIFICATION_BEGIN: 'SUBMIT_NOTIFICATION_BEGIN',
  SUBMIT_NOTIFICATION_END: 'SUBMIT_NOTIFICATION_END',
  TOGGLE_LEFT_SIDEBAR: 'TOGGLE_LEFT_SIDEBAR',
  TOGGLE_RIGHT_SIDEBAR: 'TOGGLE_RIGHT_SIDEBAR',
  UPDATE_CURRENT_USER_BEGIN: 'UPDATE_CURRENT_USER_BEGIN',
  UPDATE_CURRENT_USER_END: 'UPDATE_CURRENT_USER_BEGIN',
  VIEW_ASSET: 'VIEW_ASSET',
};

export const STATUS_COLOR_MAP = {
  Needy: 'red',
  Available: 'blue',
  Adopted: 'green',
  Yours: 'orange',
};

export const ACTION_ICON_MAP = {
  Serviced: 'wrench',
  Notified: 'exclamation',
  Adopted: 'check',
  Abandoned: 'times',
};
