import { TYPES } from 'common/constants';
import * as client from 'services/client';

const getAssetsEnd = payload => ({
  type: TYPES.GET_ASSETS_END,
  payload,
});

const getAssetsBegin = payload => ({
  type: TYPES.GET_ASSETS_BEGIN,
  payload,
});

export const getAssets = position => dispatch =>
  Promise.all([
    dispatch(getAssetsBegin(position)),
    client
      .fetchAssets({
        position: [
          position._southWest.lat,
          position._southWest.lng,
          position._northEast.lat,
          position._northEast.lng,
        ],
      })
      .then(
        response => dispatch(getAssetsEnd(response)),
        error => console.log(error),
      ),
  ]);

export const setType = type => ({
  type: TYPES.SET_TYPE,
  payload: {
    type,
  },
});

export const setStatus = status => ({
  type: TYPES.SET_STATUS,
  payload: {
    status,
  },
});

export const resetServiceFilters = () => ({
  type: TYPES.RESET_SERVICE_FILTERS,
});

export const setServiceFilters = payload => ({
  type: TYPES.SET_SERVICE_FILTERS,
  payload,
});

export const focusAsset = payload => ({
  type: TYPES.FOCUS_ASSET,
  payload,
});

export const blurAsset = payload => ({
  type: TYPES.BLUR_ASSET,
  payload,
});

export const popupOpen = payload => ({
  type: TYPES.POPUP_OPEN,
  payload,
});

export const popupClose = payload => ({
  type: TYPES.POPUP_CLOSE,
  payload,
});

export const setSortBy = payload => ({
  type: TYPES.SET_SORT_BY,
  payload,
});

export const viewAsset = payload => ({
  type: TYPES.VIEW_ASSET,
  payload,
});

const getAssetEventsBegin = payload => ({
  type: TYPES.GET_ASSET_EVENTS_BEGIN,
  payload,
});

const getAssetEventsEnd = payload => ({
  type: TYPES.GET_ASSET_EVENTS_END,
  payload,
});

export const getAssetEvents = payload => dispatch =>
  Promise.all([
    dispatch(getAssetEventsBegin(payload)),
    client.fetchAssetEvents(payload).then(
      response =>
        dispatch(getAssetEventsEnd({ id: payload, events: response })),
      error => console.log(error),
    ),
  ]);

export const resetFilters = () => ({
  type: TYPES.RESET_FILTERS,
});

const getVolunteersBegin = payload => ({
  type: TYPES.GET_VOLUNTEERS_BEGIN,
  payload,
});

const getVolunteersEnd = payload => ({
  type: TYPES.GET_VOLUNTEERS_END,
  payload,
});

export const getVolunteers = payload => dispatch =>
  Promise.all([
    dispatch(getVolunteersBegin(payload)),
    client.fetchVolunteers(payload).then(
      response => dispatch(getVolunteersEnd(response)),
      error => console.log(error),
    ),
  ]);
