import { Link } from 'react-router-dom';
import moment from 'moment';
import React from 'react';

import { blockElementModifier, paginate } from 'common/helpers';
import { STATUS_COLOR_MAP, PATHS } from 'common/constants';
import Filters from 'components/Filters';
import Indicator from 'components/Indicator';
import Paginate from 'components/Paginate';
import Welcome from 'components/Welcome';

const bem = blockElementModifier('AssetList');

function AssetList({ assets, onBlurAsset, onFocusAsset, onViewAsset, history, location }) {
  const search = new URLSearchParams(location.search);
  const page = parseInt(search.get('page')) || 0;
  return (
    <div className={bem()}>
      <Welcome
        headline="Take part in helping your city"
        strapline="Vancouver has over 45,000 catch basins removing excess water from our streets at every rainfall. By adopting a catch basin, you can help keep your neighbourhood a vibrant, safe, and healthy space."
      />
      <Filters />
      {paginate(assets, 25, page + 1).map(asset => (
        <div
          className={bem('item')}
          key={asset.id}
          onMouseEnter={() => onFocusAsset(asset.id)}
          onMouseLeave={() => onBlurAsset(asset.id)}
        >
          <div className={bem('details')}>
            <div className={bem('heading')}>
              <div className={bem('name')}>{asset.name}</div>
              {asset.owner && (
                <div className={bem('owner')}>
                  {asset.status === 'Yours'
                    ? 'Yours'
                    : asset.owner.first_name + ' ' + asset.owner.last_name}
                </div>
              )}
            </div>
            <div className={bem('meta')}>
              <Indicator color={STATUS_COLOR_MAP[asset.status]} size="tiny" />
              <div className={bem('type')}>{asset.type}</div>
              {asset.last_serviced_at && (
                <div className={bem('date')}>
                  {moment(asset.last_serviced_at).fromNow()}
                </div>
              )}
            </div>
          </div>
          <div className={bem('actions')}>
            <div className={bem('button')}>
              <Link
                to={PATHS.ASSET_SHOW.replace(':id', asset.id)}
                onClick={e =>
                  onViewAsset({
                    id: asset.id,
                    position: {
                      latitude: asset.position[0],
                      longitude: asset.position[1],
                    },
                  })
                }
                className="Button Button--small"
              >
                Details
              </Link>
            </div>
          </div>
        </div>
      ))}
      <Paginate
        total={assets.length}
        perPage={25}
        page={page}
        initialPage={page}
        onPageChange={e => history.push(`${PATHS.ASSET_ROOT}?page=${e.selected}`)}
      />
    </div>
  );
}

export default AssetList;
