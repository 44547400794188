import React from 'react';

import { blockElementModifier } from 'common/helpers';
import Label from 'components/Label';

const bem = blockElementModifier('Textarea');

function Textarea({ label, ...props }) {
  return (
    <div className={bem()}>
      {label && <Label>{label}</Label>}
      <textarea className={bem('control')} {...props} />
    </div>
  );
}

export default Textarea;
