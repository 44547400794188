import { connect } from 'react-redux';

import './AssetList.scss';
import { bindActionCreators } from 'redux';
import {
  blurAsset,
  focusAsset,
  setSortBy,
  viewAsset,
} from 'store/actions/canvas';
import {
  selectAssetsWithFiltersAndSortApplied,
  selectSortBy,
} from 'store/selectors/canvas';
import AssetList from './AssetList';

const mapStateToProps = state => ({
  assets: selectAssetsWithFiltersAndSortApplied(state),
  sortBy: selectSortBy(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onFocusAsset: focusAsset,
      onSetSortBy: setSortBy,
      onBlurAsset: blurAsset,
      onViewAsset: viewAsset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssetList);
