import { Grid, Row, Col } from 'react-flexbox-grid';
import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';
import Button from 'components/Button';
import Field from 'components/Field';
import Input from 'components/Input';
import LayoutWithSplit from 'components/LayoutWithSplit';
import Toast from 'components/Toast';
import Steps from 'components/Steps';
import { PATHS } from 'common/constants';

const bem = blockElementModifier('CompleteProfile');

class CompleteProfile extends Component {
  state = {
    input: {
      address: '',
      city: '',
      first_name: '',
      last_name: '',
      phone: '',
      state: '',
      zip: '',
    },
    warnings: {},
  };

  componentDidMount() {
    const { onClearMessage } = this.props;
    onClearMessage();
  }

  setInput(nextInput) {
    const { input, ...state } = this.state;
    this.setState({
      ...state,
      input: {
        ...input,
        ...nextInput,
      },
    });
  }

  handleOnNext() {
    const { onUpdateCurrentUser, history } = this.props;
    onUpdateCurrentUser(this.state.input);
    history.push(PATHS.VOLUNTEER);
  }

  render() {
    const { message } = this.props;
    const { input } = this.state;
    return (
      <LayoutWithSplit
        register
      >
        <div className={bem()}>
          <Grid fluid>
            <div className={bem('headline')}>Create your account</div>
            {message && <Toast message={message} />}
            <Steps step={2} />
          </Grid>
          <Grid fluid>
            <form
              onSubmit={e => {
                e.preventDefault();
                this.handleOnNext(e);
              }}
            >
              <Row>
                <Col xs={12} md={6}>
                  <Field>
                    <Input
                      label="Given Name"
                      type="text"
                      value={input.first_name}
                      onChange={e =>
                        this.setInput({ first_name: e.target.value })
                      }
                      required
                    />
                  </Field>
                </Col>
                <Col xs={12} md={6}>
                  <Field>
                    <Input
                      label="Family Name"
                      type="text"
                      value={input.last_name}
                      onChange={e =>
                        this.setInput({ last_name: e.target.value })
                      }
                      required
                    />
                  </Field>
                </Col>
              </Row>
              <Field>
                <Input
                  label="Address"
                  type="text"
                  value={input.address}
                  onChange={e => this.setInput({ address: e.target.value })}
                  required
                />
              </Field>
              <Row>
                <Col xs={12} md={4}>
                  <Field>
                    <Input
                      label="City"
                      type="text"
                      value={input.city}
                      onChange={e => this.setInput({ city: e.target.value })}
                      required
                    />
                  </Field>
                </Col>
                <Col xs={12} md={3}>
                  <Field>
                    <Input
                      label="Province"
                      type="text"
                      value={input.state}
                      onChange={e => this.setInput({ state: e.target.value })}
                      required
                    />
                  </Field>
                </Col>
                <Col xs={12} md={5}>
                  <Field>
                    <Input
                      label="Postal Code"
                      type="text"
                      value={input.zip}
                      onChange={e => this.setInput({ zip: e.target.value })}
                      required
                    />
                  </Field>
                </Col>
              </Row>
              <Field>
                <Input
                  label="Phone"
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  value={input.phone}
                  onChange={e => this.setInput({ phone: e.target.value })}
                  required
                />
              </Field>
              <Button block label="Save" />
            </form>
          </Grid>
        </div>
      </LayoutWithSplit>
    );
  }
}

export default CompleteProfile;
