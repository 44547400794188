import { Map, TileLayer, Marker, Polygon } from 'react-leaflet';
import classNames from 'classnames';
import Leaflet from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import React, { Component } from 'react';

import './AssetCanvas.scss';
import { blockElementModifier } from 'common/helpers';
import { STATUS_COLOR_MAP, PATHS } from 'common/constants';
import Icon from 'components/Icon';

const bem = blockElementModifier('AssetCanvas');

class AssetCanvas extends Component {
  componentDidMount() {
    this.handleOnFetchAssets();
  }

  handleOnFetchAssets(e) {
    const { onGetItems } = this.props;
    if (this.ref) {
      onGetItems(this.ref.leafletElement.getBounds());
    }
  }

  componentWillReceiveProps(nextProps) {
    const { resizeLayout, onResizeLayoutDone } = this.props;

    if (
      this.ref &&
      nextProps.resizeLayout &&
      resizeLayout !== nextProps.resizeLayout
    ) {
      this.ref.leafletElement.invalidateSize();
      onResizeLayoutDone();
    }
  }

  componentDidUpdate(prevProps) {
    const { onGetItems, latitude, longitude } = this.props;

    if (
      this.ref &&
      prevProps.latitude &&
      prevProps.longitude &&
      latitude !== prevProps.latitude &&
      longitude !== prevProps.longitude
    ) {
      onGetItems(this.ref.leafletElement.getBounds());
    }
  }

  render() {
    const {
      assets,
      history,
      latitude,
      longitude,
      match,
      onPopupClose,
      onPopupOpen,
      onLeftSidebarToggle,
      leftSidebarIsOpen,
    } = this.props;
    return (
      <div className={bem()}>
        <button className={bem('toggle')} onClick={onLeftSidebarToggle}>
          <Icon
            glyph={leftSidebarIsOpen ? 'arrow-to-left' : 'arrow-to-right'}
            weight="light"
          />
        </button>
        <Map
          ref={ref => {
            this.ref = ref;
          }}
          onPopupClose={e => onPopupClose(e.popup.options.asset.id)}
          onPopupOpen={e => onPopupOpen(e.popup.options.asset.id)}
          onClick={() => history.push(PATHS.ASSET_ROOT)}
          onMoveStart={() => history.push(PATHS.ASSET_ROOT)}
          onMoveEnd={() => this.handleOnFetchAssets()}
          className={bem('map')}
          center={[latitude, longitude]}
          zoomControl={false}
          zoom={16}
          minZoom={16}
          maxZoom={20}
        >
          <TileLayer
            accessToken={process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            id="mapbox/streets-v11"
            url={process.env.REACT_APP_MAP_BOX_URL}
          />
          <MarkerClusterGroup
            showCoverageOnHover={false}
            iconCreateFunction={cluster => {
              return Leaflet.divIcon({
                html: `<div class="Indicator Indicator--grey Indicator--larger">${cluster.getChildCount()}</div>`,
              });
            }}
          >
            {assets.map(asset => (
              <Marker
                key={asset.id}
                position={asset.position}
                zIndexOffset={asset.id === parseInt(match.params.id) ? 500 : 0}
                onClick={() =>
                  history.push(PATHS.ASSET_SHOW.replace(':id', asset.id))
                }
                icon={Leaflet.divIcon({
                  html: `<div class="${classNames(
                    'Indicator',
                    `Indicator--${
                      asset.id === parseInt(match.params.id) ? 'large' : 'small'
                    }`,
                    `Indicator--${STATUS_COLOR_MAP[asset.status]}`,
                    'Indicator--pin',
                  )}" />`,
                  iconSize:
                    asset.id === parseInt(match.params.id)
                      ? [23, 23]
                      : [13, 13],
                  iconAnchor:
                    asset.id === parseInt(match.params.id) ? [12, 23] : [7, 13],
                })}
              >
                {asset.points && (
                  <Polygon color="blue" positions={asset.points} />
                )}
              </Marker>
            ))}
          </MarkerClusterGroup>
        </Map>
      </div>
    );
  }
}

export default AssetCanvas;
