import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Avatar from 'components/Avatar';
import Loader from 'components/Loader';
import Warning from 'components/Warning';
import Breadcrumb from 'components/Breadcrumb';

const bem = blockElementModifier('VolunteerShow');

class VolunteerShow extends Component {
  componentDidMount() {}

  render() {
    const { volunteer } = this.props;

    if (!volunteer) {
      return <Loader />;
    }

    return (
      <div className={bem()}>
        <Breadcrumb
          to="/volunteers"
          label="Volunteer List"
        />
        <div className={bem('details')}>
          <div className={bem('avatar')}>
            <Avatar size="large" />
          </div>
          <div>
            <div className={bem('name')}>
              {volunteer.first_name} {volunteer.last_name}
            </div>
            <Link
              to={PATHS.VOLUNTEER_CONTACT.replace(':id', volunteer.id)}
              className="Button Button--smaller"
            >
              Contact
            </Link>
          </div>
        </div>
        <div className={bem('autobiography')}>{volunteer.autobiography}</div>
        <div className={bem('label')}>Service Offered</div>
        <div className={bem('services')}>
          {volunteer.services.map(service => (
            <div className={bem('service')}>{service}</div>
          ))}
        </div>
        <Warning
          title="What you need to know"
          items={[
            {
              headline: 'Your safety is our priority',
              strapline:
                'Be sure to follow the guidelines of public health authorities in your area.',
            },
            {
              headline: 'Your privacy matters',
              strapline:
                'In order to protect the privacy of those seeking help, those who offer help cannot search for the people seeking help. Only the people asking for help can contact volunteers. Please keep an eye on your phone and inbox.',
            },
          ]}
        />
      </div>
    );
  }
}

export default VolunteerShow;
