import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Sidebar');

function Sidebar({ children }) {
  return (
    <div className={bem()}>
      <div className={bem('body')}>{children}</div>
    </div>
  );
}

export default Sidebar;
