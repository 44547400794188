import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Message');

function Message({ headline, strapline }) {
  return (
    <div className={bem()}>
      <div className={bem('headline')}>{headline}</div>
      <div className={bem('strapline')}>{strapline}</div>
    </div>
  );
}

export default Message;
