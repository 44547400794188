import { Grid } from 'react-flexbox-grid';
import React, { Component } from 'react';
import validate from 'validate.js';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Button from 'components/Button';
import Field from 'components/Field';
import Input from 'components/Input';
import LayoutWithSplit from 'components/LayoutWithSplit';
import { Redirect } from 'react-router-dom';

const bem = blockElementModifier('ForgotPassword');

class ResetPassword extends Component {
  state = {
    input: {
      email: '',
    },
    warnings: {},
  };

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const { onClearMessage } = this.props;
    onClearMessage();
    this.setState({
      input: {
        email: query.get('email'),
        reset_password_token: query.get('reset_password_token'),
      },
    });
  }

  handleOnSubmit(e) {
    e.preventDefault();
    const { input } = this.state;
    const { onResetPassword, history } = this.props;
    const schema = {
      password: { length: { minimum: 6 } },
      password_confirmation: {
        equality: 'password',
      },
    };
    const warnings = validate(input, schema);
    if (warnings) {
      this.setState({ warnings });
    } else {
      onResetPassword(input).then(() => {
        history.push(PATHS.ROOT);
      });
    }
  }

  render() {
    const { hasValidToken } = this.props;
    const { input, warnings } = this.state;

    if (hasValidToken) {
      return <Redirect to={PATHS.ROOT} />;
    }

    return (
      <LayoutWithSplit
        register
      >
        <div className={bem()}>
          <Grid fluid>
            <div className={bem('headline')}>Reset password</div>
            <form onSubmit={e => this.handleOnSubmit(e)}>
              <Field>
                <Input
                  label="Email"
                  type="email"
                  value={input.email}
                  autocapitalize="none"
                  disabled
                  required
                />
              </Field>
              <Field>
                <Input
                  label="Password"
                  type="text"
                  value={input.password}
                  warning={warnings.password}
                  onChange={e =>
                    this.setState({
                      ...this.state,
                      input: { ...input, password: e.target.value },
                    })
                  }
                  required
                  mask
                />
              </Field>
              <Field>
                <Input
                  label="Password Confirmation"
                  type="text"
                  value={input.password_confirmation}
                  warning={warnings.password_confirmation}
                  onChange={e =>
                    this.setState({
                      ...this.state,
                      input: {
                        ...input,
                        password_confirmation: e.target.value,
                      },
                    })
                  }
                  required
                  mask
                />
              </Field>
              <Button label="Reset Password" />
            </form>
          </Grid>
        </div>
      </LayoutWithSplit>
    );
  }
}

export default ResetPassword;
