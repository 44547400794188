import { connect } from 'react-redux';

import './LayoutWithSplit.scss';
import LayoutWithSplit from './LayoutWithSplit';
import { selectCurrentCity } from 'store/selectors/root';

const mapStateToProps = state => ({
  currentCity: selectCurrentCity(state),
});

export default connect(mapStateToProps)(LayoutWithSplit);
