import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Enticer from 'components/Enticer';
import Loader from 'components/Loader';
import Message from 'components/Message';
import Breadcrumb from 'components/Breadcrumb';

const bem = blockElementModifier('AssetAdopt');

class AssetAdopt extends Component {
  componentDidMount() {
    const { asset } = this.props;
    if (asset) {
      this.handleOnAdopt(asset);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { asset } = this.props;
    if (!asset && nextProps.asset) {
      this.handleOnAdopt(nextProps.asset);
    }
  }

  handleOnAdopt(asset) {
    const { onAdopt } = this.props;
    if (asset.status === 'Available') {
      onAdopt(asset.id);
    }
  }

  render() {
    const { asset, hasValidToken } = this.props;

    if (!asset) {
      return <Loader />;
    }

    return (
      <div className={bem()}>
        <Breadcrumb
          label={asset.name}
          to={PATHS.ASSET_SHOW.replace(':id', asset.id)}
        />
        {hasValidToken ? (
          <>
            <Message
              headline={`Thank you for adopting this ${asset.type}!`}
              strapline={`
                The city appreciates your help in keeping${' '}
                Vancouver green. Try giving your newly-adopted${' '}
                ${asset.type} a name you like.
              `}
            />
            <Link
              className="Button Button--block Button--small"
              to={PATHS.ASSET_SHOW.replace(':id', asset.id)}
            >
              See Item Details
            </Link>
          </>
        ) : (
          <Enticer
            headline={`Log in to adopt this ${asset.type}!`}
            strapline={`You’re one step closer to adopting this ${asset.type}. Just log in or sign up, and we’ll add this asset to your account.`}
          />
        )}
      </div>
    );
  }
}

export default AssetAdopt;
