import { Map, TileLayer, Marker } from 'react-leaflet';
import classNames from 'classnames';
import Leaflet from 'leaflet';
import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('ReverseGeocode');

function ReverseGeocode({ position, onChange }) {
  return (
    <div className={bem()}>
      <Map
        className={bem('map')}
        center={[position.lat, position.lng]}
        zoomControl={false}
        zoom={18}
        minZoom={18}
        maxZoom={18}
        onClick={onChange}
      >
        <TileLayer
          accessToken={process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          id="mapbox/streets-v11"
          url={process.env.REACT_APP_MAP_BOX_URL}
        />
        {position.lat && position.lng && (
          <Marker
            position={position}
            icon={Leaflet.divIcon({
              html: `<div class="${classNames(
                'Indicator',
                `Indicator--small`,
                `Indicator--blue`,
              )}" />`,
              iconSize: [13, 13],
            })}
          />
        )}
      </Map>
    </div>
  );
}

export default ReverseGeocode;
