import { TYPES } from 'common/constants';
import * as client from 'services/client';

export const leftSidebarToggle = () => ({
  type: TYPES.TOGGLE_LEFT_SIDEBAR,
});

export const markNotificationsAsReadBegin = () => ({
  type: TYPES.MARK_NOTIFICATION_AS_READ_BEGIN,
});

export const markNotificationsAsReadEnd = () => ({
  type: TYPES.MARK_NOTIFICATION_AS_READ_END,
});

export const markNotificationsAsRead = () => dispatch =>
  Promise.all([
    dispatch(markNotificationsAsReadBegin()),
    client.markNotificationsAsRead().then(
      response => dispatch(markNotificationsAsReadEnd()),
      error => console.log(error),
    ),
  ]);

export const rightSidebarToggle = () => ({
  type: TYPES.TOGGLE_RIGHT_SIDEBAR,
});

export const resizeLayout = () => ({
  type: TYPES.RESIZE_LAYOUT,
});

export const resizeLayoutDone = () => ({
  type: TYPES.RESIZE_LAYOUT_DONE,
});

export const setIsMenuOpen = payload => ({
  type: TYPES.SET_IS_MENU_OPEN,
  payload,
});
