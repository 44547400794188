import React from 'react';

import { blockElementModifier } from 'common/helpers';
import Icon from 'components/Icon';

const bem = blockElementModifier('Alert');

function Alert({ onRightSidebarToggle, unreadNotifications }) {
  return (
    <div className={bem()}>
      <button className={bem('button')} onClick={onRightSidebarToggle}>
        {unreadNotifications.length > 0 && (
          <div className={bem('count')}>{unreadNotifications.length}</div>
        )}
        <div className={bem('label')}>Inbox</div>
        <div className={bem('icon')}>
          <Icon glyph="bell" weight="regular" />
        </div>
      </button>
    </div>
  );
}

export default Alert;
