import { Link } from 'react-router-dom';
import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Button from 'components/Button';
import Enticer from 'components/Enticer';
import Field from 'components/Field';
import Hint from 'components/Hint';
import Icon from 'components/Icon';
import Message from 'components/Message';
import Select from 'components/Select';
import Textarea from 'components/Textarea';
import Warning from 'components/Warning';
import Breadcrumb from 'components/Breadcrumb';

const bem = blockElementModifier('VolunteerContact');

class VolunteerContact extends Component {
  state = {
    input: {},
    isSubmitted: false,
  };
  componentDidMount() {}

  render() {
    const { hasValidToken, volunteer } = this.props;
    const { input, isSubmitted } = this.state;

    if (!volunteer) {
      return <div>Loading</div>;
    }

    return (
      <div className={bem()}>
        <Breadcrumb
          to={PATHS.VOLUNTEER_SHOW.replace(':id', volunteer.id)}
          label={`${volunteer.first_name} ${volunteer.last_name}`}
        />
        {isSubmitted ? (
          <>
            <Message
              headline="Thanks!"
              strapline="The city appreciates your help in keeping Vancouver green. We’ve marked this asset as serviced in your maintenance log."
            />
            <Link
              className="Button Button--block Button--small"
              to={PATHS.VOLUNTEER_SHOW.replace(':id', volunteer.id)}
            >
              See Volunteer Details
            </Link>
          </>
        ) : hasValidToken ? (
          <>
            <div className={bem('heading')}>
              Contact {volunteer.first_name} directly
            </div>
            <div className={bem('contactInformation')}>
              <Icon glyph="phone" weight="solid" />
              604-123-4567
            </div>
            <div className={bem('contactInformation')}>
              <Icon glyph="envelope" weight="regular" />
              person@example.com
            </div>
            <br />
            <div className={bem('heading')}>
              Send {volunteer.first_name} a message
            </div>
            <form
              onSubmit={e => {
                e.preventDefault();
                this.setState({
                  isSubmitted: true,
                });
              }}
            >
              <Field>
                <Select
                  required
                  label="Requested Service"
                  options={volunteer.services}
                  onChange={e =>
                    this.setState({
                      ...this.state,
                      input: { ...input, service: e.target.value },
                    })
                  }
                  value={input.service}
                />
              </Field>
              <Field>
                <Textarea
                  required
                  label="Additional Information"
                  placeholder="Provide any additional details you wish to share."
                  onChange={e =>
                    this.setState({
                      ...this.state,
                      input: { ...input, message: e.target.value },
                    })
                  }
                  value={input.message}
                  rows={5}
                />
              </Field>
              <Hint>
                By clicking ‘Send Message’, you agree to share your contact
                information to the recipient.
              </Hint>
              <br />
              <Button size="small" label="Send Message" />
            </form>
            <br />
            <br />
            <br />
            <Warning
              title="What you need to know"
              items={[
                {
                  headline: 'Your safety is our priority',
                  strapline:
                    'Be sure to follow the guidelines of public health authorities in your area.',
                },
                {
                  headline: 'Your privacy matters',
                  strapline:
                    'In order to protect the privacy of those seeking help, those who offer help cannot search for the people seeking help. Only the people asking for help can contact volunteers. Please keep an eye on your phone and inbox.',
                },
              ]}
            />
          </>
        ) : (
          <Enticer
            headline={`Log in to contact ${volunteer.first_name}!`}
            strapline={`You need to be logged in to see ${volunteer.first_name}’s contact details.`}
          />
        )}
      </div>
    );
  }
}

export default VolunteerContact;
