import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './AssetShow.scss';
import AssetShow from './AssetShow';
import { selectAssetById } from 'store/selectors/canvas';
import { viewAsset, getAssetEvents } from 'store/actions/canvas';
import { setPosition } from 'store/actions/root';

const mapStateToProps = (state, ownProps) => ({
  asset: selectAssetById(state, ownProps.match.params.id),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onGetAssetEvents: getAssetEvents,
      onSetPosition: setPosition,
      onViewAsset: viewAsset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssetShow);
