import classNames from 'classnames';
import React from 'react';

import { blockElementModifier } from 'common/helpers';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';

const bem = blockElementModifier('LayoutWithSidebar');

function LayoutWithSidebar({
  children,
  isLeftSidebarOpen,
  isRightSidebarOpen,
  leftSidebar,
  currentCity,
  rightSidebar,
  onResizeLayout,
  expand,
}) {
  return (
    <div
      className={classNames(bem(), currentCity.theme, {
        [bem(null, 'left')]: isLeftSidebarOpen,
        [bem(null, 'right')]: isRightSidebarOpen,
        [bem(null, 'expand')]: expand,
      })}
    >
      <div className={bem('header')}>
        <Header />
      </div>
      <div className={bem('body')}>
        <div className={bem('sidebarLeft')}>
          <Sidebar>{leftSidebar}</Sidebar>
        </div>
        <div
          className={bem('component')}
          onTransitionEnd={() => onResizeLayout()}
        >
          {children}
        </div>
        {rightSidebar && (
          <div className={bem('sidebarRight')}>{rightSidebar}</div>
        )}
      </div>
    </div>
  );
}

export default LayoutWithSidebar;
