import classNames from 'classnames';
import React from 'react';

import { blockElementModifier } from 'common/helpers';
import Icon from 'components/Icon';

const bem = blockElementModifier('Avatar');

function Avatar({ size, type }) {
  return (
    <div className={classNames(bem(), { [bem(null, size)]: size })}>
      <Icon glyph={type || 'user'} weight="solid" />
    </div>
  );
}

export default Avatar;
