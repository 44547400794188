import classNames from 'classnames';
import React from 'react';

import { blockElementModifier } from 'common/helpers';
import Label from 'components/Label';

const bem = blockElementModifier('Field');

function Field({ children, enemies, label, hint }) {
  return (
    <div className={classNames(bem(), { [bem(null, 'enemies')]: enemies })}>
      <div>
        {label && <Label>{label}</Label>}
        {hint && <div className={bem('hint')}>{hint}</div>}
      </div>
      <div>{children}</div>
    </div>
  );
}

export default Field;
