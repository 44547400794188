import classNames from 'classnames';
import React from 'react';

import { blockElementModifier } from 'common/helpers';
import Progress from 'components/Progress';

const bem = blockElementModifier('Button');

function Button({ block, disabled, label, loading, size, semantic, ...props }) {
  return (
    <button
      className={classNames(bem(), {
        [bem(null, size)]: size,
        [bem(null, 'semantic')]: semantic,
        [bem(null, 'block')]: block,
      })}
      disabled={disabled}
      {...props}
    >
      {loading ? <Progress /> : label}
    </button>
  );
}

export default Button;
