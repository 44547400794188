import React, { Component } from 'react';

import { blockElementModifier } from 'common/helpers';
import { PATHS } from 'common/constants';
import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Message from 'components/Message';
import Breadcrumb from 'components/Breadcrumb';

const bem = blockElementModifier('AssetAbandon');

const reasons = [
  'Moving to a new city',
  'Takes up too much time/effort',
  'Safety/Privacy concerns',
];

class AssetAbandon extends Component {
  state = {
    isSubmitted: false,
  };

  render() {
    const { asset, onAbandonSubmit } = this.props;
    const { isSubmitted } = this.state;

    if (!asset) {
      return <Loader />;
    }

    return (
      <div className={bem()}>
        <Breadcrumb
          label={asset.name}
          to={PATHS.ASSET_SHOW.replace(':id', asset.id)}
        />
        {isSubmitted ? (
          <>
            <Message
              headline="Thank you!"
              strapline="We’ve marked this to your service log."
            />
            <Button
              block
              size="smaller"
              label="See Item Details"
              onClick={() => this.props.history.push(PATHS.ASSET_SHOW.replace(':id', asset.id))}
            />
          </>
        ) : (
          <>
            <div className={bem('title')}>Abandon Asset</div>
            <div className={bem('subTitle')}>What prompted you to abandon?</div>
            <div className={bem('choices')}>
              {reasons.map(reason => (
                <div className={bem('choice')}>
                  <Input
                    type="radio"
                    label={reason}
                    checked={reason === this.state.input}
                    value={reason}
                    onChange={e => this.setState({ input: reason })}
                  />
                </div>
              ))}
            </div>
            <Button
              label="Submit"
              size="small"
              disabled={!this.state.input}
              onClick={() => {
                this.setState({ isSubmitted: true });
                if (asset.status === 'Yours') {
                  onAbandonSubmit(asset.id, {
                    message: this.state.input,
                  });
                }
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default AssetAbandon;
