import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Hint');

function Hint({ children }) {
  return <div className={bem()}>{children}</div>;
}

export default Hint;
