import React from 'react';

import { blockElementModifier } from 'common/helpers';

const bem = blockElementModifier('Panel');

function Panel({ children }) {
  return <div className={bem()}>{children}</div>;
}

export default Panel;
