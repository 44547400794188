import _ from 'lodash';
import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

/**
 * Select current city
 *
 * @param {object} state
 * @returns {object}
 */
export const selectCurrentCity = state => _.get(state, ['currentCity'], null);

/**
 * Select current user
 *
 * @param {object} state
 * @returns {object}
 */
export const selectCurrentUser = state => _.get(state, ['currentUser'], null);

/**
 * Select token
 *
 * @param {object} state
 * @returns {string}
 */
export const selectToken = state => _.get(state, ['token'], '');

/**
 * Select decoded token
 *
 * @param {object} state
 * @returns {object}
 */
export const selectDecodedtoken = createSelector([selectToken], token =>
  token ? jwtDecode(token) : null,
);

/**
 * Select has valid token
 *
 * @param {object} state
 * @returns {boolean}
 */
export const selectHasValidToken = createSelector(
  [selectDecodedtoken],
  decodedToken => _.has(decodedToken, ['exp']),
);

/**
 * Select current position
 *
 * @param {object} state
 * @returns {object}
 */
export const selectCurrentPosition = state =>
  _.get(state, ['currentPosition'], null);

/**
 * Select message
 *
 * @param {object} state
 * @returns {string}
 */
export const selectMessage = state => _.get(state, ['message'], null);

/**
 * Select notifications
 *
 * @param {object} state
 * @returns {arary}
 */
export const selectNotifications = state => _.get(state, ['notifications'], []);

/**
 * Select notifications from today
 *
 * @param {object} state
 * @returns {arary}
 */
export const selectNotificationsFromToday = state => {
  const today = moment().startOf('day');
  return selectNotifications(state).filter(
    notification => moment(notification.created_at) > today,
  );
};

/**
 * Select notifications from before today
 *
 * @param {object} state
 * @returns {arary}
 */
export const selectNotificationsFromBeforeToday = state => {
  const today = moment().startOf('day');
  return selectNotifications(state).filter(
    notification => moment(notification.created_at) < today,
  );
};

/**
 * Select unread notifications
 *
 * @param {object} state
 * @returns {string}
 */
export const selectUnreadNotifications = state =>
  selectNotifications(state).filter(
    notification => notification.is_read !== true,
  );
