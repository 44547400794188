import { Switch, Route } from 'react-router-dom';
import React, { Component } from 'react';

import { PATHS } from 'common/constants';
import LayoutWithSidebar from 'components/LayoutWithSidebar';
import Loader from 'components/Loader';
import Notifications from 'components/Notifications';
import VolunteerContact from 'components/VolunteerContact';
import VolunteersCanvas from 'components/VolunteerCanvas';
import VolunteerShow from 'components/VolunteerShow';
import VolunteersList from 'components/VolunteersList';

class Volunteers extends Component {
  componentDidMount() {
    const {
      onGetCurrentUser,
      onGetNotifications,
      onGetCurrentPosition,
      hasValidToken,
    } = this.props;
    onGetCurrentPosition();
    if (hasValidToken) {
      onGetCurrentUser();
      onGetNotifications();
    }
  }

  render() {
    const {
      currentCity,
      currentPosition,
      onGetVolunteers,
      ...props
    } = this.props;
    if (currentCity) {
      return (
        <LayoutWithSidebar
          expand={!!props.match.params.id}
          leftSidebar={
            <Switch>
              <Route
                path={PATHS.VOLUNTEER_CONTACT}
                component={VolunteerContact}
              />
              <Route path={PATHS.VOLUNTEER_SHOW} component={VolunteerShow} />
              <Route path={PATHS.VOLUNTEER_LIST} component={VolunteersList} />
            </Switch>
          }
          rightSidebar={<Notifications />}
        >
          <VolunteersCanvas
            {...props}
            onGetItems={onGetVolunteers}
            latitude={
              currentPosition
                ? currentPosition.latitude
                : currentCity.position.latitude
            }
            longitude={
              currentPosition
                ? currentPosition.longitude
                : currentCity.position.longitude
            }
          />
        </LayoutWithSidebar>
      );
    }
    return <Loader />;
  }
}

export default Volunteers;
