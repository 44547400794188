import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './Geolookup.scss';
import Geolookup from './Geolookup';
import { selectCurrentCity } from 'store/selectors/root';
import { setPosition } from 'store/actions/root';

const mapStateToProps = state => ({
  currentCity: selectCurrentCity(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSetPosition: setPosition,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Geolookup);
