import { Grid, Col, Row } from 'react-flexbox-grid';
import moment from 'moment';
import React, { Component } from 'react';

import { blockElementModifier, paginate } from 'common/helpers';
import { PATHS } from 'common/constants';
import Action from 'components/Action';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Field from 'components/Field';
import Loader from 'components/Loader';
import Paginate from 'components/Paginate';
import Status from 'components/Status';
import Warning from 'components/Warning';
import Breadcrumb from 'components/Breadcrumb';

const bem = blockElementModifier('AssetShow');

class AssetShow extends Component {
  state = {
    page: 0,
  };

  componentDidMount() {
    const { asset } = this.props;
    if (asset) {
      this.handleoOnGetAssetEvents(asset);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { asset } = this.props;
    if (!asset && nextProps.asset) {
      this.handleoOnGetAssetEvents(nextProps.asset);
    }
  }

  handleoOnGetAssetEvents(asset) {
    const { onGetAssetEvents } = this.props;
    if (onGetAssetEvents) {
      onGetAssetEvents(asset.id);
    }
  }

  handlePageClick(e) {
    this.setState({
      page: e.selected,
    });
  }

  render() {
    const { asset, onSetPosition } = this.props;

    if (!asset) {
      return <Loader />;
    }

    return (
      <div className={bem()}>
        <div className={bem('header')}>
          <Breadcrumb
            to={PATHS.ASSET_ROOT}
            label="Asset List"
          />            
          <div className={bem('details')}>
            <div className={bem('avatar')}>
              <Avatar type="images" size="large" />
            </div>
            <div>
              <div className={bem('name')}>{asset.name}</div>
              {(() => {
                switch (asset.status) {
                  case 'Adopted':
                    return (
                      <Button
                        label="Notify"
                        size="smaller"
                        onClick={() =>
                          this.props.history.push(
                            PATHS.ASSET_NOTIFY.replace(':id', asset.id),
                          )
                        }
                        disabled={
                          asset.last_notified_at &&
                          moment(asset.last_notified_at).diff(
                            moment(),
                            'hours',
                          ) < 24
                        }
                      />
                    );
                  case 'Yours':
                    return (
                      <>
                        <Button
                          label="Service"
                          size="smaller"
                          onClick={() =>
                            this.props.history.push(
                              PATHS.ASSET_SERVICE.replace(':id', asset.id),
                            )
                          }
                          disabled={
                            asset.last_serviced_at &&
                            moment(asset.last_serviced_at).diff(
                              moment(),
                              'hours',
                            ) < 24
                          }
                        />
                        <Button
                          label="Abandon"
                          size="smaller"
                          onClick={() =>
                            this.props.history.push(
                              PATHS.ASSET_ABANDON.replace(':id', asset.id),
                            )
                          }
                          style={{ marginLeft: '10px' }}
                        />
                      </>
                    );
                  default:
                    return (
                      <Button
                        label="Adopt"
                        size="smaller"
                        onClick={() =>
                          this.props.history.push(
                            PATHS.ASSET_ADOPT.replace(':id', asset.id),
                          )
                        }
                      />
                    );
                }
              })()}
            </div>
          </div>
        </div>
        <div className={bem('body')}>
          <Grid fluid>
            <Row>
              <Col xs={6}>
                <Field label="Status">
                  <Status status={asset.status} />
                </Field>
              </Col>
              <Col xs={6}>
                <Field label="Owner">
                  <strong>
                    {asset.owner
                      ? `${asset.owner.first_name} ${asset.owner.last_name}`
                      : 'None'}
                  </strong>
                </Field>
              </Col>
              <Col xs={12}>
                <Field label="Location">
                  <button
                    className={bem('location')}
                    onClick={e =>
                      onSetPosition({
                        latitude: asset.position[0],
                        longitude: asset.position[1],
                      })
                    }
                  >
                    {asset.position[0]}°N, {asset.position[1]}°W
                  </button>
                </Field>
              </Col>
            </Row>
            {asset.events && asset.events.length > 0 && (
              <>
                <div className={bem('title')}>Maintenance Log</div>
                <table className={bem('table')}>
                  <thead>
                    <tr>
                      <th className={bem('tableHeader')}>Action</th>
                      <th className={bem('tableHeader')}>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginate(asset.events, 5, this.state.page + 1).map(
                      (event, i) => (
                        <tr key={i}>
                          <td className={bem('tableData')}>
                            <Action action={event.type} />
                          </td>
                          <td className={bem('tableData')}>
                            {moment(event.occurred_at).fromNow()}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
                <Paginate
                  total={asset.events.length}
                  perPage={5}
                  page={this.state.page}
                  onPageChange={e => this.handlePageClick(e)}
                />
              </>
            )}
            <br />
            <Warning
              title="How to care for catch basins"
              items={[
                {
                  strapline:
                    'Check on your catch basin before and after a heavy rainfall and when trees lose their leaves.',
                },
                {
                  strapline:
                    'Check to make sure your catch basin is clear of leaves, debris, and litter.',
                },
                {
                  strapline:
                    'If your catch basin is blocked, use a rake, broom, or shovel to push leaves and debris away from the curb and catch basin grate. This will allow water to drain into the catch basin and prevent flooding.',
                },
              ]}
            />
          </Grid>
        </div>
      </div>
    );
  }
}

export default AssetShow;
