import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './Assets.scss';
import {
  getCurrentUser,
  getCurrentPosition,
  getNotifications,
} from 'store/actions/root';
import {
  selectCurrentCity,
  selectCurrentPosition,
  selectHasValidToken,
} from 'store/selectors/root';
import Assets from './Assets';
import { getAssets } from 'store/actions/canvas';

const mapStateToProps = state => ({
  currentCity: selectCurrentCity(state),
  currentPosition: selectCurrentPosition(state),
  hasValidToken: selectHasValidToken(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onGetNotifications: getNotifications,
      onGetCurrentPosition: getCurrentPosition,
      onGetCurrentUser: getCurrentUser,
      onGetAssets: getAssets,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
