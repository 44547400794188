import React, { useState } from 'react';
import classNames from 'classnames';

import { blockElementModifier } from 'common/helpers';
import Button from 'components/Button';

const bem = blockElementModifier('Editable');

function Editable({ children, label, value }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={classNames(bem(), {
        [bem(null, 'open')]: isOpen,
      })}
    >
      <div className={bem('enemies')}>
        <div className={bem('label')}>{label}</div>
        <Button
          label={isOpen ? 'Cancel' : 'Edit'}
          onClick={() => setIsOpen(!isOpen)}
          semantic
        />
      </div>
      <div className={bem('value')}>{value}</div>
      <div className={bem('children')}>{children(setIsOpen)}</div>
    </div>
  );
}

export default Editable;
